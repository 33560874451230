import LazyLoad from "../helpers/LazyLoad";
import Base from "./Base";
import { subscribeScroll, getScroll } from "../helpers/Scroll";
import { subscribeViewport, getViewport } from "../helpers/Viewport";
import PreLoad from "../helpers/PreLoad";
import ScrollReveal from "../helpers/ScrollReveal";


export default class Page extends Base {
  assetsLoaded = false;
  shown = false;
  DURATION_SHOW = 700;
  timer_show = null;
  modules = [];

  constructor(options) {
    super(options);

    this.scrollRevealModules = Array.prototype.slice.call(document.querySelectorAll('.observer-reveal'));
  }


  bindEvents() {
    this.subscriptionScroll = subscribeScroll(this.onScroll);
    this.subscriptionViewport = subscribeViewport(this.onResize);
  }

  onScroll = () => {
    // console.log('scroll from page.js');
  }

  onResize = () => {}

  init() {
    this.initModules();

    this.bindEvents();
  }

  async show() {
    return new Promise((resolve, reject) => {
      this.timer_show = setTimeout(() => {
        clearTimeout(this.timer_show);
        this.timer_show = null;

        if (this.el) {
          this.showView();

          setTimeout(() => {

            this.initLazyLoad();

            this.preloadAssets();

            this.initScrollReveal();
          }, 0);


          resolve();
        }
      }, 1000);
    });
  }

  initModules() {

  }

  initLazyLoad() {
    this.lazyLoad = new LazyLoad({
      container: this.el,
      selector: ".lazy"
    });
  }

  preloadAssets() {
    if(Array.prototype.slice.call(this.el.querySelectorAll('.preload')).length > 0) { //are there assets to preload?
      this.preLoad = new PreLoad({
        container: this.el,
        selector: ".preload",
        onAllLoaded: this.onAllLoaded
      });
    } else {
      this.onAllLoaded();
    }

  }

  onAllLoaded = () => {
    this.assetsLoaded = true;
    this.onShown();
  }

  onShown() {
    this.shown = true;
    this.onScroll();
  }

  hide() {
    if (this.el) {
      this.el.classList.remove('show');
      this.el.classList.add("hide");
    }

    this.shown = false;
  }

  initScrollReveal() {
    this.scrollRevealModules.forEach((el) => {
      new ScrollReveal(el, { callback: this.onReveal });
    });
  }

  showView() {
    // console.log(' show view....')
    // window.scrollTo(0, 0);
    this.el.classList.remove("hide");
  }

  onReveal = scrollReveal => {
    scrollReveal.element.classList.add('revealed');
  }

  dispose() {
    super.dispose();

    if (this.subscriptionScroll) this.subscriptionScroll();
        this.subscriptionScroll = null;

    if (this.subscriptionViewport) this.subscriptionViewport();
        this.subscriptionViewport = null;

    if (this.timer_show) clearTimeout(this.timer_show);
    this.timer_show = null;

    if (this.lazyLoad) this.lazyLoad.dispose();
    this.lazyLoad = null;

    this.modules.forEach((module) => {
      if(module.dispose) module.dispose();
    });

    this.unbindEvents();
  }
}
