export default [
    'es_all_demographics',
    'es_trends_by_intent_and_year',
    'es_leading_cause_of_death',
    'es_gun_deaths_children_teens',
    'es_gun_deaths_by_county',
    // Legacy/Pre-consolidation
    //'es_gun_deaths_by_intent_overall',
    //'es_overview_data',
    //'es_intent_pie_chart',
    //'es_gun_deaths_trends_chart',
    //'es_gun_deaths_landscape',
    'es_gun_injuries_landscape', // KB, more to come
    'es_gun_cost_landscape', // KB, more to come
    //'es_firearm_suicide_overview', // replaced 021423
    //'es_firearm_suicide_race_ethnicity',
    //'es_firearm_homicide_overview', // replaced 021423
    //'es_firearm_homicide_race_ethnicity',
    //'es_children_teen_leading_death',
    //'es_children_teen_overview',
    //'es_children_teen_race_ethnicity',
    'es_intimate_partner_homicide',
    'es_firearm_intimate_partner_homicide',
    //'es_county_data',
    //'es_database_data',
    'es_trans_homicide_data', // Keep, this is loaded via Airtable.
    //'es_societal_costs_firearm_injury_data',
    //'es_government_costs_firearm_injury_data',
    //'es_per_capita_costs_and_ranking_data',
    //'es_total_costs_data',
    'es_congress_map_data'
]