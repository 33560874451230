import { getScroll } from './Scroll'

class ScrollLock {
    constructor () {
        this.locked = false
    }

    lock () {
        this.locked = true
        this.currentScrollPosition = getScroll().y
        document.documentElement.style.setProperty('--scroll-position', this.currentScrollPosition)
        document.documentElement.classList.add('es-lockedScroll')
    }

    unlock () {
        this.locked = false
        document.documentElement.classList.remove('es-lockedScroll')
        window.scrollTo(0, this.currentScrollPosition)
    }
}

const lock = new ScrollLock()
export default lock
