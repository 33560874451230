import { TweenMax } from "gsap/all"
import { subscribeScroll } from '../helpers/Scroll'

class Source {
    constructor ($parent, mobile = false) {
        this.mobile = mobile
        this.$parent = $parent
        this.$element = this.$parent.querySelector('.es-source');
        // this.$parent.appendChild(this.$element)
        //this.displayed = false
        this.addEventListeners()
    }

    addEventListeners () {
        this.$element.addEventListener('mouseover', this.showTooltip)
        this.$element.addEventListener('mouseleave', this.hideTooltip)
        subscribeScroll(this.hideTooltip)
    }

    showTooltip = () => {
        if (!this.displayed) {
            if(!this.$element.querySelector('.es-source-description')) return;
            this.displayed = true
            const rect = this.$element.querySelector('.es-source-caption').getBoundingClientRect()
            TweenMax.set(this.$element.querySelector('.es-source-description'), {
                top: rect.top,
                left: window.innerWidth < 700 ? "50%" : rect.left + rect.width / 2,
                //left: window.innerWidth < 700 ? rect.left + rect.width : rect.left + rect.width / 2,
                className: '+=es-displayed'
            })
            setTimeout(() => {
                this.$element.querySelector('.es-source-description').addEventListener('click', this.hideTooltip)
            }, 300)
        }
    }

    hideTooltip = () => {
        if(!this.$element.querySelector('.es-source-description')) return;
        this.$element.querySelector('.es-source-description').removeEventListener('click', this.hideTooltip)
        if (this.displayed) {
            this.displayed = false            
            TweenMax.set(this.$element.querySelector('.es-source-description'), {
                className: '-=es-displayed'
            })
        }
    }

    /* createDOM () {
        const parser = new DOMParser()
        let template = `
            <div class="es-source ${this.mobile ? '-es-mobile' : ''}">
                <span class="es-source-caption">${this.caption}</span>
        `
        if (this.description !== null) {
            template += `<div class="es-source-description"><p>${this.description}</p></div>`
        }

        template += `
            </div>
        `
        return parser.parseFromString(template, 'text/html').body.firstChild
    } */
}

export default Source
