import dataKeys from '../constants/dataKeys';
import jsonQ from 'js-jsonq'; // API Documentations for data filtering https://awesomeopensource.com/project/me-shaon/js-jsonq#fetch
import { tsv } from 'd3-fetch';
// Note: data in C:\xampp\htdocs\wp_etresearch\wp-content\uploads\2021\07 (or similar) is modified so all instances of "United States" have a "fips_state" of "00"
class ESData {
    constructor() {
        this.dataChunks = {};
    }
    loadAllData = (data) => {
        return new Promise((resolve, reject) => {
            let dataCounter = 0;
            console.log("dataKeys", dataKeys)
            dataKeys.forEach( key => {
                console.log(key)
                const url = data[key].url;
                tsv(url, function(d) {
                    let obj = {};
                    Object.keys(d).forEach(key => {
                        if(!isNaN(d[key]) && key !== 'fips_state' && key !== 'statefips' && key !== 'fips_county') {
                            obj[key] = +d[key]
                        }
                    })
                    return {
                        ...d,
                        ...obj
                    };
                }).then(data => {
                    this.dataChunks[key] = new jsonQ(data);
                    dataCounter++;
                    if(dataCounter === dataKeys.length) {
                        resolve();
                    }
                })
            } )
        })
    }
    loadDBData = (data) => {
        return new Promise((resolve, reject) => {
            let dataCounter = 0;
            dataKeys.forEach( key => {
                //if(key === 'es_database_data') {
                if(key === 'es_all_demographics') {
                    const url = data[key].url;
                    tsv(url, function(d) {
                        let obj = {};
                        Object.keys(d).forEach(key => {
                            if(!isNaN(d[key]) && key !== 'fips_state' && key !== 'statefips' && key !== 'fips_county') {
                                obj[key] = +d[key]
                            }
                        })
                        return {
                            ...d,
                            ...obj
                        };
                    }).then(data => {
                        this.dataChunks[key] = new jsonQ(data);
                        dataCounter++;
                        if(dataCounter === 1) {
                            resolve();
                        }
                    })
                }
            } )
        })
    }
    /* DATA NO LONGER USED, replaced with costingBlurb 
    overviewBlurb = fips => {
        const blurbData = this.dataChunks.es_overview_data.copy();
        // per year and rate
        const stateFips = fips;
        const singleRecord = blurbData
            .where('fips_state', '=', stateFips)
            .fetch()[0];

        const perYear = singleRecord.total_deaths / (singleRecord.last_year - singleRecord.first_year + 1);
        const rate = singleRecord.age_adj_rate;

        // rank
        blurbData.reset();

        const rank = blurbData
            .where('age_adj_rate', '>', rate)
            .where('state', '!=', 'United States')
            .count() + 1;
        console.log("'!=', 'United States' - rank", rank)
        const blurbObj = {
            perYear: perYear,
            rate: rate.toFixed(1),
            rank: rank
        }
        return blurbObj;
    }*/    
    costingBlurb = fips => {

        //const costingBlurbData = this.dataChunks.es_total_costs_data.copy();
        const costingBlurbData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", costingBlurbData);
        // per year and rate
        const stateFips = fips;
        const singleRecordGov = costingBlurbData // 432,974,951
            .where('intent_num', '=', '21')
            .where('fips_state', '=', stateFips)
            .where('payer', '=', 'Government')
            .fetch()[0];        
        costingBlurbData.reset();  
        
        const singleRecordSoc = costingBlurbData // 8,064,225,788
            .where('intent_num', '=', '21')
            .where('fips_state', '=', stateFips)
            .where('payer', '=', 'Society')
            .fetch()[0];
        costingBlurbData.reset();

        const costBlurbObj = {
            total_cost_gov: "$" + this.formatBigNumberETRFormula(singleRecordGov.total_cost), // divide by billion, round to 0.1
            total_cost_soc: "$" + this.formatBigNumberETRFormula(singleRecordSoc.total_cost), // divide by million, round to 0.1
            //all_govt_cost: this.formatBigNumber(singleRecord. all_govt_cost),
            //all_cost_resident: singleRecord.all_cost_resident,
            //all_cost_resident_rank: this.ordinalSuffixOf(singleRecord.all_cost_rank)
        }
        return costBlurbObj;
    }
    suicideCostingBlurb = fips => {
        /*
        const suicideCostingBlurbData = this.dataChunks.es_total_costs_data.copy();
        // per year and rate
        const stateFips = fips;
        const singleRecord = suicideCostingBlurbData
            .where('fips_state', '=', stateFips)
            .fetch()[0];

        suicideCostingBlurbData.reset();
        */
        const costingBlurbData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", costingBlurbData);
        // per year and rate
        const stateFips = fips;
        const singleRecordGov = costingBlurbData // 432,974,951
            .where('intent_num', '=', '22')
            .where('fips_state', '=', stateFips)
            .where('payer', '=', 'Government')
            .fetch()[0];        
        costingBlurbData.reset();  
        
        const singleRecordSoc = costingBlurbData // 8,064,225,788
            .where('intent_num', '=', '22')
            .where('fips_state', '=', stateFips)
            .where('payer', '=', 'Society')
            .fetch()[0];
        costingBlurbData.reset();

        const suicideCostBlurbObj = {
            suicide_cost_total: this.formatBigNumberETRFormula(singleRecordSoc.total_cost), //$[total_cost, payer=society, intent_num=22, divide by billion, round to 0.1] billion
            suicide_cost_resident: singleRecordSoc.percapita, // $[percapita, payer=society, intent_num=22] per person
            suicide_cost_rank: this.ordinalSuffixOf(singleRecordSoc.rank) // [rank, payer=society, intent_num=22][th/st] 
        }
        return suicideCostBlurbObj;
    }
    
    homicideCostingBlurb = fips => {

        /*
        const homicideCostingBlurbData = this.dataChunks.es_total_costs_data.copy();
        // per year and rate
        const stateFips = fips;
        const singleRecord = homicideCostingBlurbData
            .where('fips_state', '=', stateFips)
            .fetch()[0];

        homicideCostingBlurbData.reset();
        */
        
        const costingBlurbData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", costingBlurbData);
        // per year and rate
        const stateFips = fips;
        const singleRecordGov = costingBlurbData // 432,974,951
           .where('intent_num', '=', '23')
           .where('fips_state', '=', stateFips)
           .where('payer', '=', 'Government')
           .fetch()[0];        
        costingBlurbData.reset();  
       
        const singleRecordSoc = costingBlurbData // 8,064,225,788
           .where('intent_num', '=', '23')
           .where('fips_state', '=', stateFips)
           .where('payer', '=', 'Society')
           .fetch()[0];
        costingBlurbData.reset();

        const homicideCostBlurbObj = {
            homicide_cost_total: this.formatBigNumber(singleRecordSoc.total_cost), // $[total_cost, payer=society, intent_num=23, divide by billion, round to 0.1]
            homicide_cost_resident: singleRecordSoc.percapita, // $[percapita, payer=society, intent_num=23]
            homicide_cost_rank: this.ordinalSuffixOf(singleRecordSoc.rank) // [rank, payer=society, intent_num=23][th/st]
        }
        return homicideCostBlurbObj;
    }

    heatmapData = (intent = 1, fips = null, _heatmapTabSelected = null, caller = "") => {
        const heatmapData_ = this.dataChunks.es_all_demographics.copy();
        console.log("FILE es_all_demographics", heatmapData_);
        const heatmapInjuriesStateData = this.dataChunks.es_gun_injuries_landscape.copy();
        console.log("FILE es_gun_injuries_landscape", heatmapInjuriesStateData);
        const heatmapCostStateData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", heatmapCostStateData);
        //const heatmapCostingData = this.dataChunks.es_total_costs_data.copy();
        const intentsLookup = {
            21:	{ "title":"ALL DEATHS AND INJURIES"},
            22:	{ "title":"SUICIDES/SUICIDE ATTEMPTS"},
            23:	{ "title":"HOMICIDES/ASSAULTS/SHOOTINGS BY POLICE"},
            28:	{ "title":"UNINTENTIONAL/UNDETERMINED"},
            11: { "title":"ALL GUN INJURIES"},
            12: { "title":"SUICIDE ATTEMPTS"},
            13: { "title":"ASSAULTS/SHOOTINGS BY POLICE"},
            18: { "title":"UNINTENTIONAL/UNDETERMINED"},
            1: { "title":"ALL GUN DEATHS"},
            3: { "title":"HOMICIDES/SHOOTINGS BY POLICE"},
            2: { "title":"SUICIDES"},
            5: { "title":"UNINTENTIONAL"},
            6: { "title":"UNDETERMINED"},
            4: { "title":"N/A"}
        }  
        const tabLookup = {
            "tab-gun-deaths":"GUN DEATHS",
            "tab-economic-cost":"ECONOMIC COST",
            "tab-gun-injuries":"GUN INJURIES",
        }

        if (tabLookup[_heatmapTabSelected]){
            var intent2 = intent;
            if (tabLookup[_heatmapTabSelected] === "ECONOMIC COST"){
                intent2 = intent + 10;
            }
            console.log("043024 Data.js > heatmapData() _heatmapTabSelected ", _heatmapTabSelected  )
            console.log("043024 Data.js > heatmapData() intent ", intent2 )
            console.log("043024 Data.js > heatmapData() intent name ", intentsLookup[intent2].title )
            console.log("043024 Data.js > heatmapData() tab ", tabLookup[_heatmapTabSelected] )
        }

        let heatmapObj_ = {};

        heatmapData_.reset();
        heatmapInjuriesStateData.reset();
        heatmapCostStateData.reset();
        //heatmapCostingData.reset();

        var altIntent = 1;
        if (intent < 10){ 
            // Proceed with original functionality 

            const nationalIntent_ = heatmapData_
                .where('intent_num', '=', intent)
                .where('state', '=', 'United States')
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .first();

            const nationalCost = heatmapCostStateData //heatmapCostingData 
                .where('state', '=', 'United States')
                .first();

            const nationalNum_ = nationalIntent_.deaths / (nationalIntent_.last_year - nationalIntent_.first_year + 1);

            heatmapData_.reset();

            heatmapInjuriesStateData.reset();
            heatmapCostStateData.reset();
            // Original costing data, replace?
            //heatmapCostingData.reset();         
                
            const statesByIntent_ = heatmapData_
                .where('intent_num', '=', intent)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .where('state', '!=', 'United States') // SEE DATA USE SUMMARY, BELOW
                .fetch();

            // DATA USE SUMMARY: 
            //  - CAUTION! const maxValue = Math.max(...states.map(state => state.value)) // max value determined from this set. USA could in theory be max value. SHOULD be OK to exclude United States in above where clause.  
            console.log("'!=', 'United States' - statesByIntent_", statesByIntent_)           
            
            heatmapObj_ = {
                intent_num: intent,
                intent_filter: 'gun deaths',
                allStates: statesByIntent_,
                nationalDeathsPerYear: nationalNum_,
                nationalDeathsRatePer100k: nationalIntent_.age_adj_rate,
                //nationalCostObject: nationalCost
            }
        }else{ // If this is one of the alternate intents (on alternate filter system), process differently.
            altIntent = intent;
            intent = 1 // DELETEME Temp hack to keep app from crashing        
                
            const nationalIntent_ = heatmapData_
                .where('intent_num', '=', intent)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .where('state', '=', 'United States')
                .first();

            console.log("122121 heatmapData nationalIntent_", nationalIntent_)
            
            const nationalCost = heatmapCostStateData // heatmapCostingData 
                //.where('state', '=', 'Wisconsin') // CS: COSTING REINGAGE, Study this. Needs refinement.  When the variable is ready on United States uncomment the below nd take out this temp code
                .where('state', '=', 'United States')
                .first();
            const nationalNum_ = nationalIntent_.deaths / (nationalIntent_.last_year - nationalIntent_.first_year + 1);

            heatmapData_.reset();
            heatmapInjuriesStateData.reset();
            heatmapCostStateData.reset();
            //heatmapCostingData.reset();
            
            if (_heatmapTabSelected == "tab-economic-cost"){
                altIntent = altIntent + 10; // Increase the intent number by 10 to query economic cost data (e.g. 11 vs 21)
                var statesByIntentCost = [];
                if (fips){
                    statesByIntentCost = heatmapCostStateData
                        .where('intent_num', '=', altIntent)
                        //.where('payer', '=', 'Government') // Government
                        .where('payer', '=', 'Society') // Society
                        .where('fips_state', '=', fips)
                        .where('state', '!=', 'United States') // SEE DATA USE SUMMARY, BELOW
                        .fetch();     
                    // DATA USE SUMMARY: 
                    //  - CAUTION! const maxValue = Math.max(...states.map(state => state.value)) // max value determined from this set. USA could in theory be max value. SHOULD be OK to exclude United States in above where clause.  
                        console.log("102422 '!=', 'United States' - statesByIntentCost", statesByIntentCost);
                        if (statesByIntentCost.length){
                            statesByIntentCost[0]["DISPLAYABLE_rank"] =  this.ordinalSuffixOf(statesByIntentCost[0]["rank"])  
                            statesByIntentCost[0]["DISPLAYABLE_percapita"] =  "$" + this.numberWithCommas(statesByIntentCost[0]["percapita"])
                        }         
                }    
                heatmapCostStateData.reset();

                var allStatesByIntentCost = heatmapCostStateData
                    .where('intent_num', '=', altIntent)
                    //.where('payer', '=', 'Government') // Government
                    .where('payer', '=', 'Society') // Society
                    .where('state', '!=', 'United States')
                    .fetch();
                // DATA USE SUMMARY: 
                //  - CAUTION! const maxValue = Math.max(...states.map(state => state.value)) // max value determined from this set. USA could in theory be max value. SHOULD be OK to exclude United States in above where clause.  
                // console.log("'!=', 'United States' - AllStatesByIntentCost", AllStatesByIntentCost)
                heatmapCostStateData.reset();
                console.log("ddd", allStatesByIntentCost) 
                
                const nationalByIntentCost = heatmapCostStateData
                    .where('intent_num', '=', altIntent)
                    //.where('payer', '=', 'Government') // Government
                    .where('payer', '=', 'Society') // Society
                    .where('state', '=', 'United States')
                    .fetch();
                heatmapCostStateData.reset();  

                //nationalByIntentCost[0]["DISPLAYABLE_total_cost"] = "$" + this.formatBigNumber(nationalByIntentCost[0]["total_cost"])
                nationalByIntentCost[0]["DISPLAYABLE_total_cost"] = "$" + this.formatBigNumberETRFormula(nationalByIntentCost[0]["total_cost"])
                nationalByIntentCost[0]["DISPLAYABLE_percapita"] =  "$" + this.numberWithCommas(nationalByIntentCost[0]["percapita"])    

                heatmapObj_ = {
                    intent_num: altIntent,
                    intent_filter: 'economic cost',
                    states: statesByIntentCost,
                    national: nationalByIntentCost,
                    allStates: allStatesByIntentCost,
                    nationalDeathsPerYear: nationalNum_,
                    nationalDeathsRatePer100k: nationalIntent_.age_adj_rate,
                    //nationalCostObject: nationalCost
                }
            }else{ // _heatmapTabSelected ==  tab-gun-deaths
                var statesByIntentInjuries = [];
                if (fips){
                    statesByIntentInjuries = heatmapInjuriesStateData
                        .where('intent_num', '=', altIntent)
                        .where('fips_state', '=', fips)
                        .fetch();                 
                    statesByIntentInjuries[0]["DISPLAYABLE_Nonfatal Injury Count"] =  this.numberWithCommas(statesByIntentInjuries[0]["Nonfatal Injury Count"])
                    statesByIntentInjuries[0]["DISPLAYABLE_Nonfatal Injury Rate"] = (Math.round((statesByIntentInjuries[0]["Nonfatal Injury Rate"]) * 10) / 10).toFixed(1)
                    statesByIntentInjuries[0]["DISPLAYABLE_State Rank"] =  this.ordinalSuffixOf(statesByIntentInjuries[0]["State Rank"])
                }
                heatmapInjuriesStateData.reset(); 

                var allStatesByIntentInjuries = heatmapInjuriesStateData
                    .where('intent_num', '=', altIntent)
                    .where('state', '!=', 'United States')
                    .fetch(); 
                // DATA USE SUMMARY: 
                //  - CAUTION! const maxValue = Math.max(...states.map(state => state.value)) // max value determined from this set. USA could in theory be max value. SHOULD be OK to exclude United States in above where clause.  
                // console.log("'!=', 'United States' - altIntent", altIntent)              
                heatmapInjuriesStateData.reset();

                const nationalByIntentInjuries = heatmapInjuriesStateData
                    .where('intent_num', '=', altIntent)
                    .where('state', '=', 'United States')
                    .fetch();
                heatmapInjuriesStateData.reset(); 

                nationalByIntentInjuries[0]["DISPLAYABLE_Nonfatal Injury Count"] =  this.numberWithCommas(nationalByIntentInjuries[0]["Nonfatal Injury Count"])
                nationalByIntentInjuries[0]["DISPLAYABLE_Nonfatal Injury Rate"] =  (Math.round((nationalByIntentInjuries[0]["Nonfatal Injury Rate"]) * 10) / 10).toFixed(1)             
                
                heatmapObj_ = {
                    intent_num: altIntent,
                    intent_filter: 'gun injuries',
                    states: statesByIntentInjuries,
                    national: nationalByIntentInjuries,
                    allStates: allStatesByIntentInjuries,
                    nationalDeathsPerYear: nationalNum_,
                    nationalDeathsRatePer100k: nationalIntent_.age_adj_rate,
                    //nationalCostObject: nationalCost
                }
            }
        }

        if(fips) {
            heatmapData_.reset();
            
            const singleRecord_ = heatmapData_
                .where('statefips', '=', fips)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .where('intent_num', '=', intent)
                .fetch()[0];

            const singleCostRecord = heatmapCostStateData // heatmapCostingData
                .where('fips_state', '=', fips)
                .fetch()[0];            

            const rate_ = singleRecord_.age_adj_rate;
            const perYear_ = singleRecord_.deaths / (singleRecord_.last_year - singleRecord_.first_year + 1);

            heatmapData_.reset();          
                
            const rank_ = heatmapData_
                .where('age_adj_rate', '>', rate_)
                .where('intent_num', '=', intent)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .where('state', '!=', 'United States')
                .count() + 1;

            console.log("'!=', 'United States' - rank_", rank_)           
            console.log("050124 singleRecord_",singleRecord_)
            heatmapObj_ = {
                ...heatmapObj_,
                rate: rate_,
                perYear: perYear_,
                allYears: singleRecord_.deaths,
                numberOfYears: (singleRecord_.last_year - singleRecord_.first_year + 1),
                rank: rank_,
                stateObject: singleCostRecord
            }

            heatmapData_.reset();
            //heatmapCostingData.reset();
        }
        return heatmapObj_;
    }
    
    overviewPieChartData = fips => {
        const pieChartData = this.dataChunks.es_all_demographics.copy();   
        console.log("FILE es_all_demographics", pieChartData);
        console.log("G041923 fips", fips);
        const stateByIntent = pieChartData
            .where('statefips', '=', fips)
            .where('intent_num', '!=', 1)
            .where('intent_num', '!=', 33)
            .where('intent_num', '!=', 23)
            .where('intent_num', '!=', 3)
            .where('gender_num', '==', 0)
            .where('race_num', '==', 0)
            .fetch();
        
        pieChartData.reset();

        const stateTotalDeaths = pieChartData
            .where('statefips', '=', fips)
            .where('intent_num', '=', 1)           
            .where('gender_num', '==', 0)
            .where('race_num', '==', 0)
            .fetch()[0].deaths;

        const pieChartObj = {
            totalDeaths: stateTotalDeaths,
            ...stateByIntent
        }
    
        return pieChartObj;
    }
    
    // Grab US data from F.1-Data-by-County 
    usData = (fips) => { // OK
        const countyDataUS = this.dataChunks.es_gun_deaths_by_county.copy();
        console.log("FILE es_gun_deaths_by_county", countyDataUS); 
        const allUS = countyDataUS
            .where('statefips', '=', fips)
            .fetch();
        console.log("county test ", allUS)
        return allUS; 
    }

    stateTrends = (fips) => {
        //const trendsDataX = this.dataChunks.es_gun_deaths_trends_chart.copy();
        const trendsData = this.dataChunks.es_trends_by_intent_and_year.copy();
        console.log("FILE es_trends_by_intent_and_year", trendsData);
        //console.log("A.3 trendsDataX", trendsDataX)
        console.log("B011322 A.3 trendsData", trendsData)

        const allDeaths = trendsData
            .where('statefips', '=', fips)
            .where('intent_num', '=', 1)
            .sortBy('year')
            .fetch(); 
        console.log("B011322 trendsData.first()", trendsData.first())
        console.log("B011322 trendsData.last()", trendsData.last())
        console.log("B011322 trendsData.max('age_adj_rate')", trendsData.max('age_adj_rate'))
        const scaleMax = trendsData.max('age_adj_rate');
        const firstYearRate = trendsData.first().age_adj_rate;
        const lastYearRate = trendsData.last().age_adj_rate;

        trendsData.reset();

        const suicideDeaths = trendsData
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .sortBy('year')
            .fetch();

        trendsData.reset();

        const homicideDeaths = trendsData
            .where('statefips', '=', fips)
            .where('intent_num', '=', 3)
            .sortBy('year')
            .fetch();

        trendsData.reset();

        const allDeathsNational = trendsData // Keep! this filter must remain for the .first()/.last() calculations below.
            .where('statefips', '=', '00')
            .where('intent_num', '=', 1)
            .sortBy('year')
            .fetch(); 
        console.log("B011322 trendsData.first()", trendsData.first())
        console.log("B011322 trendsData.last()", trendsData.last())
        const nationalFirstYearRate = trendsData.first().age_adj_rate;
        const nationalYearRate = trendsData.last().age_adj_rate;

        // Get all years suicide rates for US
        trendsData.reset();
        const nationalSuicideDeathsRate = trendsData
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .sortBy('year')
            .fetch();

        // Get all years homicide rates for US
        trendsData.reset();
        const nationalHomicideDeathsRate = trendsData
            .where('state', '=', 'United States')
            .where('intent_num', '=', 3)
            .sortBy('year')
            .fetch();
        console.log("B011322 nationalYearRate", nationalYearRate)  
        console.log("B011322 nationalFirstYearRate", nationalFirstYearRate)  
        console.log("B011322 ((nationalYearRate - nationalFirstYearRate) / nationalFirstYearRate) * 100", ((nationalYearRate - nationalFirstYearRate) / nationalFirstYearRate) * 100)  
        console.log("B011322 lastYearRate", lastYearRate)  
        console.log("B011322 firstYearRate", firstYearRate)  
        console.log("B011322 ((lastYearRate - firstYearRate) / firstYearRate) * 100", ((lastYearRate - firstYearRate) / firstYearRate) * 100)  
        const trendsObj = {
            scaleMax: scaleMax.toFixed(1),
            totalDeaths: allDeaths,
            suicideDeaths: suicideDeaths,
            homicideDeaths: homicideDeaths,
            stateChange: ((lastYearRate - firstYearRate) / firstYearRate) * 100,
            nationalChange: ((nationalYearRate - nationalFirstYearRate) / nationalFirstYearRate) * 100,
            nationalSuicideDeathsRate: nationalSuicideDeathsRate,
            nationalHomicideDeathsRate: nationalHomicideDeathsRate
        }

        return trendsObj;
    }

    suicideOverview = (fips) => {
        const suicideData_ = this.dataChunks.es_all_demographics.copy();   
        console.log("FILE es_all_demographics", suicideData_);
        const nationalSuicides_ = suicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        suicideData_.reset()    
        const nationalSuicides_intent_1 = suicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first()

        suicideData_.reset()  
        const nationalSuicides_intent_2 = suicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first()
        
        let suicideObj_ = {
            nationalSuicideTotal: nationalSuicides_.deaths / (nationalSuicides_.last_year - nationalSuicides_.first_year + 1),
            nationalSuicideRate: nationalSuicides_.age_adj_rate.toFixed(1),
            nationalSuicidePerc: (nationalSuicides_intent_2.deaths / nationalSuicides_intent_1.deaths),
        }

        suicideData_.reset()        
         
        const stateSuicides_intent_1 = suicideData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 1) // All Gun Deaths
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();
            
        suicideData_.reset()    
        const stateSuicides_intent_2 = suicideData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2) // Gun Suicide
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();    
            
        suicideData_.reset();

        const stateTotalSuicides = suicideData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 23)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        suicideData_.reset();

        const stateTotalFirearmSuicides = suicideData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        suicideData_.reset();

        const nationalTotalSuicides = suicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 23)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        suicideData_.reset();

        const nationalTotalFirearmSuicides = suicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();
    
        console.log("030222 nationalTotalSuicides", nationalTotalSuicides)
        console.log("030222 nationalTotalFirearmSuicides",nationalTotalFirearmSuicides)
        console.log("030222 stateTotalSuicides", stateTotalSuicides)
        console.log("030222 stateTotalFirearmSuicides",stateTotalFirearmSuicides)
        suicideObj_ = {
            ...suicideObj_,
            stateSuicideDeaths: stateSuicides_intent_2.deaths,
            stateSuicideTotal: stateSuicides_intent_2.deaths / (stateSuicides_intent_2.last_year - stateSuicides_intent_2.first_year + 1),
            stateSuicideRate: stateSuicides_intent_2.age_adj_rate.toFixed(1),
            stateSuicidePerc: (stateSuicides_intent_2.deaths / stateSuicides_intent_1.deaths),
            statePercSuicideGun: stateTotalFirearmSuicides.deaths / stateTotalSuicides.deaths,
            nationalPercSuicideGun: nationalTotalFirearmSuicides.deaths / nationalTotalSuicides.deaths,
        }

        suicideData_.reset();

        const rank_ = suicideData_
            .where('intent_num', '=', 2) // Gun Suicide
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .where('age_adj_rate', '>', stateSuicides_intent_2.age_adj_rate)
            .where('state', '!=', 'United States')
            .count() + 1;

        suicideObj_.stateSuicideRank = rank_;

        return suicideObj_;

    }

    /* "Age-adjusted rate for white people (race_ethnicity_num = 2) / Age-adjusted rate for Black people (race_ethnicity_num = 2) where intent = 2" */

    suicideByRaceEthnicity = (fips) => {
        const suicideRaceData_ = this.dataChunks.es_all_demographics.copy();   
        console.log("FILE es_all_demographics", suicideRaceData_);
        console.log("fips", fips)

        const suicidesByAllRaces_ = suicideRaceData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '!=', 0)
            .fetch();
            
        suicideRaceData_.reset();

        const suicideMaxRate = suicideRaceData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '!=', 0)
            .max('age_adj_rate');

        suicideRaceData_.reset();

        const stateSuicidesByWhite = suicideRaceData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 2)
            .first();

        suicideRaceData_.reset();

        const stateSuicidesByBlack = suicideRaceData_
        .where('statefips', '=', fips)
        .where('intent_num', '=', 2)
        .where('gender_num', '=', 0)
        .where('age_group_num', '=', 0)
        .where('race_num', '=', 1)
        .first();

        suicideRaceData_.reset();

        const nationalSuicidesByWhite = suicideRaceData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 2)
            .first();

        suicideRaceData_.reset();

        const nationalSuicidesByBlack = suicideRaceData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 1)
            .first();

        const suicidesByRace = {
            max: suicideMaxRate.toFixed(1),
            allRaces: suicidesByAllRaces_,
            stateWhiteData: stateSuicidesByWhite,
            stateBlackData: stateSuicidesByBlack,
            nationalWhiteData: nationalSuicidesByWhite,
            nationalBlackData: nationalSuicidesByBlack,
        }

        return suicidesByRace;
    }

    homicideOverview = (fips) => {
        const homicideData_ = this.dataChunks.es_all_demographics.copy(); 
        console.log("FILE es_all_demographics", homicideData_); 
        const nationalHomicides_ = homicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();        
            //.fetch();

        homicideData_.reset();
        const nationalHomicides_1 = homicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        homicideData_.reset();
        const nationalHomicides_33 = homicideData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 33)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 0)
            .first();
            //.fetch();

        console.log("gun homicide nationalHomicides_", nationalHomicides_)
        console.log("gun homicide nationalHomicides_1", nationalHomicides_1)
        console.log("gun homicide nationalHomicides_.deaths", nationalHomicides_.deaths)
        console.log("gun homicide nationalHomicides_1.deaths", nationalHomicides_1.deaths)

        let homicideObj_ = {
            nationalHomicideTotal: nationalHomicides_.deaths / (nationalHomicides_.last_year - nationalHomicides_.first_year + 1),
            nationalHomicideRate: nationalHomicides_.age_adj_rate.toFixed(1),
            nationalHomicidePerc: nationalHomicides_.deaths / nationalHomicides_1.deaths,
            nationalPercHomicideGun: nationalHomicides_.deaths / nationalHomicides_33.deaths // nationalHomicides_.perc_homicide_gun,
        }

        homicideData_.reset()

        if (fips !== "00"){ // Only show national/USA data in event of 00/United States selected
            //document.querySelector('.es-bucket-drawer-wrapper-homicide .es-topline-stats-copy').classList.remove('es-topline-stats-copy-off')
            document.body.querySelectorAll('.es-bucket-drawer-wrapper-fit-vertical-vp').forEach(element => {
                element.classList.remove("es-bucket-drawer-wrapper-fit-vertical-vp-STACK") 
            })
            document.body.querySelectorAll('.es-state-on-off').forEach(element => {
                element.classList.remove("es-topline-stats-copy-off") 
            })            
            document.body.querySelectorAll('.es-bucket-state-data-bg-img').forEach(element => {
                element.classList.remove("es-bucket-state-data-bg-img-us") 
            })

            const stateHomicides_ = homicideData_
                .where('statefips', '=', fips)
                .where('intent_num', '=', 3)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .first();

            homicideData_.reset();
            const stateHomicides_1 = homicideData_
                .where('statefips', '=', fips)
                .where('intent_num', '=', 1)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .first();

            homicideData_.reset();
            const stateHomicides_33 = homicideData_
                .where('statefips', '=', fips)
                .where('intent_num', '=', 33)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .first();

            homicideObj_ = {
                ...homicideObj_,
                stateHomicideDeaths: stateHomicides_.deaths,
                stateHomicideTotal: stateHomicides_.deaths / (stateHomicides_.last_year - stateHomicides_.first_year + 1),
                stateHomicideRate: stateHomicides_.age_adj_rate.toFixed(1),
                stateHomicidePerc: stateHomicides_.deaths / stateHomicides_1.deaths,
                statePercHomicideGun: stateHomicides_.deaths / stateHomicides_33.deaths,
            }

            homicideData_.reset();
        
            const rank_ = homicideData_
                .where('age_adj_rate', '>', stateHomicides_.age_adj_rate)
                .where('state', '!=', 'United States')
                .where('intent_num', '=', 3)
                .where('gender_num', '=', 0)
                .where('age_group_num', '=', 0)
                .where('race_num', '=', 0)
                .count() + 1;

            homicideObj_.stateHomicideRank = rank_;

        }else{
            //document.querySelector('.es-bucket-drawer-wrapper-homicide .es-topline-stats-copy').classList.add('es-topline-stats-copy-off')
            document.body.querySelectorAll('.es-bucket-drawer-wrapper-fit-vertical-vp').forEach(element => {
                element.classList.add("es-bucket-drawer-wrapper-fit-vertical-vp-STACK") 
            })
            document.body.querySelectorAll('.es-state-on-off').forEach(element => {
                element.classList.add("es-topline-stats-copy-off") 
            })            
            document.body.querySelectorAll('.es-bucket-state-data-bg-img').forEach(element => {
                element.classList.add("es-bucket-state-data-bg-img-us") 
            })
        }

        return homicideObj_;

    }

    homicideByRace = (fips) => {
        const homicideRaceData_ = this.dataChunks.es_all_demographics.copy(); 
        console.log("FILE es_all_demographics", homicideRaceData_); 
        const homicidesByWhite = homicideRaceData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 2)
            .first();

        homicideRaceData_.reset();

        const homicidesByBlack = homicideRaceData_
        .where('statefips', '=', fips)
        .where('intent_num', '=', 3)
        .where('gender_num', '=', 0)
        .where('age_group_num', '=', 0)
        .where('race_num', '=', 1)
        .first();

        homicideRaceData_.reset();

        const nationalHomicidesByWhite = homicideRaceData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '=', 2)
            .first();

        homicideRaceData_.reset();

        const nationalHomicidesByBlack = homicideRaceData_
        .where('state', '=', 'United States')
        .where('intent_num', '=', 3)
        .where('gender_num', '=', 0)
        .where('age_group_num', '=', 0)
        .where('race_num', '=', 1)
        .first();

        homicideRaceData_.reset();

        const allRaces = homicideRaceData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '!=', 0)
            .fetch();

        const max = homicideRaceData_
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('age_group_num', '=', 0)
            .where('race_num', '!=', 0)
            .max('age_adj_rate');

        const homicidesByRace = {
            whiteData: homicidesByWhite,
            blackData: homicidesByBlack,
            nationalWhiteData: nationalHomicidesByWhite,
            nationalBlackData: nationalHomicidesByBlack,
            allRaces: allRaces,
            max: max.toFixed(1)
        }
        return homicidesByRace;
    }

    iphByRace = (fips) => {
        const iphRaceData = this.dataChunks.es_firearm_intimate_partner_homicide.copy();
        console.log("FILE es_firearm_intimate_partner_homicide", iphRaceData); 
        console.log("120821 iphRaceData", iphRaceData)
            
        const allRacesIPH = iphRaceData
            .where('fips_state', '=', fips)
            .fetch();
            
        console.log("120821 allRacesIPH", allRacesIPH)

        iphRaceData.reset();
        let max = 0;//iphRaceData.max('cruderate');
        allRacesIPH.forEach(key => {
            if (key['cruderate'] > max){
                max = key['cruderate']
            }
        })
        console.log("120821 max", max)

        const iphByRace = {
            allRaces: allRacesIPH, // Required
            max: max.toFixed(1), // Required
            varToUse:'cruderate', // Need to tell the VerticalBarGraph class to render off this var 
            raceVarToUse:'race_ethnicity_num', // Need to tell the VerticalBarGraph class to recognise race off this var 
            caller:"iph"
        }

        return iphByRace;
    }

    countyDataByIntent = (fips) => { // OK
        const countyData = this.dataChunks.es_gun_deaths_by_county.copy();
        console.log("020723 FILE es_gun_deaths_by_county", countyData); 
        const countyByStateAndIntent = countyData
            .where('statefips', '=', fips)
            .sortBy('population', 'desc')
            .fetch();
        console.log("020723 countyByStateAndIntent", countyByStateAndIntent)
        return countyByStateAndIntent;
    }       
    
    congressBlurbData = (fips) => {
        const congressData = this.dataChunks.es_congress_map_data.copy();
        console.log("FILE es_congress_map_data", congressData); 
        const congressBlurbData = congressData
            .where('fips_state', '=', fips)
            //.sortBy('population', 'desc')
            .fetch();
        console.log("congressBlurbData:", congressBlurbData)
        return congressBlurbData;
    }     

    congressBlurbDataUSA = () => {
        const congressDataUSA = this.dataChunks.es_congress_map_data.copy();
        console.log("FILE es_congress_map_data", congressDataUSA); 
        const congressBlurbDataUSA = congressDataUSA
            .where('fips_state', '!=', "11") // Exclude DC from this number because it does not have voting representation
            .fetch();
        console.log("congressBlurbDataUSA:", congressBlurbDataUSA)
        return congressBlurbDataUSA;
    } 

    congressMapData = (_fips, _currentIntent) => {
        const congressData = this.dataChunks.es_congress_map_data.copy();
        console.log("FILE es_congress_map_data", congressData); 
        const congressMapData = congressData
            .where('intent_num', '=', 2)
            //.sortBy('population', 'desc')
            .fetch();

        const congressMapDataKeyed = {};

        congressMapData.forEach(function(obj) {           
             var _geoid = (obj["GEOID"]).toString();
             if (_geoid.length == 3){ 
                _geoid = "0" + _geoid;
             }
             congressMapDataKeyed[_geoid] = obj
        }); 
        console.log("congressMapDataKeyed:", congressMapDataKeyed)
        return congressMapDataKeyed;
    }      
    
    congressMapDataArray = (_fips, _currentIntent) => {
        const congressData = this.dataChunks.es_congress_map_data.copy();
        console.log("FILE es_congress_map_data", congressData); 
        const congressMapData = congressData
            .where('intent_num', '=', 2)
            //.sortBy('population', 'desc')
            .fetch();
        
        const congressMapDataModified = [];

        congressMapData.forEach(function(obj) {           
            var stateModField = obj["state"] + "-" + obj["cd_text"].toString();
            var _geoid = (obj["GEOID"]).toString();
            if (_geoid.length == 3){ 
                _geoid = "0" + _geoid;
            }
            obj["GEOID_STR"] = _geoid; 
            if (obj["cd116"] == "At large"){
                obj["state_disp"] = obj["state"];
            }else{
                if (obj["cd116"].toString().length == 1){ 
                    obj["state_disp"] = obj["state"] + " 0" + obj["cd116"].toString();
                }else{
                    obj["state_disp"] = obj["state"] + " " + obj["cd116"].toString();
                }
            }
            
            obj["total_deaths_disp"] = (obj["total_deaths"]).toString() + " per year"; 
            obj["fss_cd_to_sort"] = parseFloat(obj["fss_cd"].replace(/,/g, '').replace(/</g, '').replace(/>/g, ''));

            if (((obj["crude_rate"]).toString().includes("<"))||((obj["crude_rate"]).toString().includes(">"))){
                obj["crude_rate_disp"] = obj["crude_rate"]
            }else{
                obj["crude_rate_disp"] = obj["crude_rate"].toFixed(1)
            }

            congressMapDataModified.push(obj)
        }); 
        console.log("congressMapDataModified:", congressMapDataModified)
        return congressMapDataModified;
    }    
    
    costingHistogramDataHomicide = (fips) => {
        const countyData = this.dataChunks.es_gun_deaths_by_county.copy();
        console.log("FILE es_gun_deaths_by_county", countyData); 
        const countyByStateAndIntent = {};
        countyByStateAndIntent["countyData"] = countyData
            .where('statefips', '=', fips)
            .sortBy('population', 'desc')
            .fetch()
        /*
        Cost bar graph
        Data source: https://github.com/everytown/research-cost/blob/master/EveryStat/cost%20by%20state.csv 
        Source: TED R MILLER ANALYSIS OF CDC FATAL INJURY: 2018 AND HCUP NONFATAL INJURY: 2017.
        Title: Economic Cost of Gun Homicides, Assaults, and Shootings by Police
        Column headers: Rank | State | Cost per Person
        Values: $[percapita, payer=society, intent_num=23] selected state highlighted in red bar, red text
        */
        //const costingHistogramDataHomicideRaw = this.dataChunks.es_total_costs_data.copy();
        const costingBlurbData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", costingBlurbData); 
        /*const costingHistogramDataHomicide = {};
        costingHistogramDataHomicide["stateData"] = costingHistogramDataHomicideRaw
            .sortBy('homicide_cost_resident', 'desc')
            .fetch()
        */
        let costingHistogramDataHomicide = {};
        costingHistogramDataHomicide = costingBlurbData
            .where('payer', '=', 'Society')
            .where('intent_num', '=', 23)
            //.where('state', '!=', 'United States')
            .sortBy('percapita', 'desc')
            .fetch();   
        // DATA USE SUMMARY: 
        //  - (element.percapita / highRecord) * 100; // percapita is weighted against highest percapita value (OK for USA)
        //  console.log("'!=', 'United States' - costingHistogramDataHomicide", costingHistogramDataHomicide)      
        costingBlurbData.reset(); 

        // selected state       
        const singleRecord = costingBlurbData //costingHistogramDataSuicideRaw
            .where('payer', '=', 'Society')
            .where('intent_num', '=', 23)
            .where('fips_state', '=', fips)
            .fetch()[0];        
        costingBlurbData.reset(); 
        console.log("county test ", countyByStateAndIntent)
        return {
            "coByStateIntent":countyByStateAndIntent,
            "allStates":costingHistogramDataHomicide,
            "selectedState":singleRecord
        };
    }    
    
    costingHistogramDataSuicide = (fips) => {
        const countyData = this.dataChunks.es_gun_deaths_by_county.copy();
        console.log("FILE es_gun_deaths_by_county", countyData); 
        const countyByStateAndIntent = {};
        countyByStateAndIntent["countyData"] = countyData
            .where('statefips', '=', fips)
            .sortBy('population', 'desc')
            .fetch()
        /*
        Cost bar graph
        Data source: https://github.com/everytown/research-cost/blob/master/EveryStat/cost%20by%20state.csv 
        Source: TED R MILLER ANALYSIS OF CDC FATAL INJURY: 2018 AND HCUP NONFATAL INJURY: 2017.
        Title: Economic Cost of Gun Suicides and Attempts
        Column headers: Rank | State | Cost per Person
        Values: $[percapita, payer=society, intent_num=22] selected state highlighted in red bar, red text
        */
        const costingBlurbData = this.dataChunks.es_gun_cost_landscape.copy();
        console.log("FILE es_gun_cost_landscape", costingBlurbData); 
        console.log("120820 costingBlurbData", costingBlurbData)
        let costingHistogramDataSuicide = {};
        costingHistogramDataSuicide = costingBlurbData
           .where('payer', '=', 'Society')
           .where('intent_num', '=', 22)
           //.where('state', '!=', 'United States') 
           .sortBy('percapita', 'desc')
           .fetch();
        // DATA USE SUMMARY: 
        //  - (element.percapita / highRecord) * 100; // percapita is weighted against highest percapita value (OK for USA)
        // console.log("'!=', 'United States' - costingHistogramDataSuicide", costingHistogramDataSuicide)        
        costingBlurbData.reset();  

        /*
        const costingHistogramDataSuicideRaw = this.dataChunks.es_total_costs_data.copy();
        const costingHistogramDataSuicide = {};
        costingHistogramDataSuicide["stateData"] = costingHistogramDataSuicideRaw
            .sortBy('suicide_cost_resident', 'desc')
            .fetch()
        */
        // selected state
        const singleRecord = costingBlurbData //costingHistogramDataSuicideRaw
            .where('payer', '=', 'Society')
            .where('intent_num', '=', 22)
            .where('fips_state', '=', fips)
            .fetch()[0];        
        costingBlurbData.reset(); 
        console.log("120820 costingHistogramDataSuicide",costingHistogramDataSuicide)
        console.log("120820 singleRecord", singleRecord)
        console.log("county test ", countyByStateAndIntent)
        return {
            "coByStateIntent":countyByStateAndIntent,
            "allStates":costingHistogramDataSuicide,
            "selectedState":singleRecord
        };
    }

    childrenAndTeens = (fips) => {
        const childrenAndTeensData_ = this.dataChunks.es_gun_deaths_children_teens.copy();
        console.log("FILE es_gun_deaths_children_teens", childrenAndTeensData_); 
        console.log("CT041923 fips", fips); 
        const childrenAndTeenNational_ = childrenAndTeensData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first();        

        childrenAndTeensData_.reset();
        const childrenAndTeenNational_suicide = childrenAndTeensData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first(); 

        childrenAndTeensData_.reset();
        const childrenAndTeenNational_homicide = childrenAndTeensData_
            .where('state', '=', 'United States')
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        childrenAndTeensData_.reset(); 
        
        let childrenAndTeenObj_ = {};

        childrenAndTeenObj_.nationalDeathsPerYear = childrenAndTeenNational_.deaths / (1 + childrenAndTeenNational_.last_year - childrenAndTeenNational_.first_year);
        //childrenAndTeenObj.nationalHighestIntent = childrenAndTeenNational.high_perc_intent;
        childrenAndTeenObj_.nationalPercentageHomicide = childrenAndTeenNational_homicide.deaths / childrenAndTeenNational_.deaths;
        childrenAndTeenObj_.nationalPercentageSuicide = childrenAndTeenNational_suicide.deaths / childrenAndTeenNational_.deaths;

        childrenAndTeensData_.reset();
            
        const childrenAndTeenState_ = childrenAndTeensData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first();        
        
        childrenAndTeensData_.reset();
        const childrenAndTeenState_fetch = childrenAndTeensData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .fetch();

        childrenAndTeensData_.reset();
        const childrenAndTeenState_suicide = childrenAndTeensData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first();    

        childrenAndTeensData_.reset();
        const childrenAndTeenState_homicide = childrenAndTeensData_
            .where('statefips', '=', fips)
            .where('intent_num', '=', 3)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .first();

        childrenAndTeensData_.reset();
        const childrenAndTeenState_otherintents = childrenAndTeensData_
            .where('statefips', '=', fips)
            .where('intent_num', '!=', 3)
            .where('intent_num', '!=', 2)
            .where('intent_num', '!=', 1)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .fetch();

        childrenAndTeensData_.reset();


        
        childrenAndTeenObj_.stateDeathsPerYear = childrenAndTeenState_.deaths / (1 + childrenAndTeenState_.last_year - childrenAndTeenState_.first_year);
        //childrenAndTeenObj.stateHighestIntent = childrenAndTeenState.high_perc_intent;
        console.log("A011722 childrenAndTeenState_", childrenAndTeenState_)
        console.log("A011722 childrenAndTeenObj_", childrenAndTeenObj_)
        console.log("A011722 childrenAndTeenObj_.stateDeathsPerYear", childrenAndTeenObj_.stateDeathsPerYear)
        console.log("A011722 childrenAndTeenState_fetch", childrenAndTeenState_fetch)
        console.log("A011722 childrenAndTeenState_.first_year", childrenAndTeenState_.first_year)
        console.log("A011722 childrenAndTeenState_.last_year", childrenAndTeenState_.last_year)
        console.log("A011722 childrenAndTeenState_homicide.deaths / childrenAndTeenState_.deaths", childrenAndTeenState_homicide.deaths / childrenAndTeenState_.deaths)
        console.log("A011722 childrenAndTeenState_suicide.deaths / childrenAndTeenState_.deaths", childrenAndTeenState_suicide.deaths / childrenAndTeenState_.deaths)
        console.log("A011722 childrenAndTeenState_.deaths", childrenAndTeenState_.deaths)
        console.log("A011722 childrenAndTeenState_homicide.deaths", childrenAndTeenState_homicide.deaths)
        console.log("A011722 childrenAndTeenState_otherintents", childrenAndTeenState_otherintents)

        childrenAndTeenObj_.statePercentageHomicide = childrenAndTeenState_homicide.deaths / childrenAndTeenState_.deaths;
        childrenAndTeenObj_.statePercentageSuicide = childrenAndTeenState_suicide.deaths / childrenAndTeenState_.deaths;
        childrenAndTeenObj_.stateAllDeaths = childrenAndTeenState_.deaths;
        childrenAndTeenObj_.stateAllDeathsAVG = childrenAndTeenState_.deaths / (1 + childrenAndTeenState_.last_year - childrenAndTeenState_.first_year);
        childrenAndTeenObj_.stateOtherIntents = childrenAndTeenState_otherintents;
        childrenAndTeenObj_.stateSuicideDeaths = childrenAndTeenState_suicide.deaths;
        childrenAndTeenObj_.stateSuicideDeathsAVG = childrenAndTeenState_suicide.deaths / (1 + childrenAndTeenState_suicide.last_year - childrenAndTeenState_suicide.first_year);
        childrenAndTeenObj_.stateHomicideDeaths = childrenAndTeenState_homicide.deaths;
        childrenAndTeenObj_.stateHomicideDeathsAVG = childrenAndTeenState_homicide.deaths / (1 + childrenAndTeenState_homicide.last_year - childrenAndTeenState_homicide.first_year);
        
        console.log("A011722 childrenAndTeenObj_.statePercentageSuicide", childrenAndTeenObj_.statePercentageSuicide)
        console.log("A011722 childrenAndTeenObj_.statePercentageHomicide", childrenAndTeenObj_.statePercentageHomicide)
        return childrenAndTeenObj_;
    }

    childrenAndTeenCauses = (fips) => {
        const childrenAndTeenCausesData_ = this.dataChunks.es_leading_cause_of_death.copy();      
        console.log("FILE es_leading_cause_of_death", childrenAndTeenCausesData_);  
        const childrenAndTeenCauseOfDeath_ = childrenAndTeenCausesData_
            .where('statefips', '=', fips)
            .sortBy('deaths', 'desc')
            .fetch();

        return childrenAndTeenCauseOfDeath_;
    }

    childrenAndTeenByRaceCompare = (fips) => {
        console.log("010322 ", fips)
       // if (fips != "00"){
            const childrenAndTeenRaceData_ = this.dataChunks.es_gun_deaths_children_teens.copy();
            console.log("FILE es_gun_deaths_children_teens", childrenAndTeenRaceData_); 
            const stateCTBlack_= childrenAndTeenRaceData_
                .where('statefips', '=', fips)
                .where('race_num', '=', 1)
                .first();

            childrenAndTeenRaceData_.reset();

            const stateCTWhite_ = childrenAndTeenRaceData_
                .where('statefips', '=', fips)
                .where('race_num', '=', 2)
                .first();

            return Math.round(stateCTBlack_.cruderate / stateCTWhite_.cruderate);
       // }else{
       //     return null;
       // }
    }

    iphOverview = (fips) => {
        const iphData = this.dataChunks.es_intimate_partner_homicide.copy();
        console.log("010522 FILE es_intimate_partner_homicide", iphData); 
        const iphNationalData = iphData
            .where('state', '=', 'United States')
            .first();

        let iphObj = {};

        iphObj.nationalFemDeathsPerYear = iphNationalData.firearm_iph_female;
        iphObj.nationalPercGunFemale = iphNationalData.perc_gun_female;

        iphData.reset();

        const iphStateData = iphData
            .where('fips_state', '=', fips)
            .first();

        console.log("021523 iphStateData",iphStateData)
        iphObj.year = iphStateData.year;
        iphObj.stateFemDeathsPerYear = iphStateData.firearm_iph_female;
        iphObj.statePercGunFemale = iphStateData.perc_gun_female;
        iphObj.statePercFirearmIphFemale = iphStateData.perc_firearm_iph_female
        iphObj.stateBlackWomanLkelihood = iphStateData.iph_fem_race_compare;

        return iphObj;
    }

    dbRaceStat = (fips) => {
        const dbData_ = this.dataChunks.es_all_demographics.copy();    
        console.log("FILE es_all_demographics", dbData_); 
        const stateBlackRate_ = dbData_
            .where('statefips', '=', fips)
            .where('race_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('intent_num', '=', 1)
            .first();

        dbData_.reset();

        const stateWhiteRate_ = dbData_
            .where('statefips', '=', fips)
            .where('race_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('intent_num', '=', 1)
            .first();

        dbData_.reset();

        const nationalBlackRate_ = dbData_
            .where('state', '=', 'United States')
            .where('race_num', '=', 1)
            .where('gender_num', '=', 0)
            .where('intent_num', '=', 1)
            .first();

        dbData_.reset();

        const nationalWhiteRate_ = dbData_
            .where('state', '=', 'United States')
            .where('race_num', '=', 2)
            .where('gender_num', '=', 0)
            .where('intent_num', '=', 1)
            .first();
        
        return {
            stateBlackRate: stateBlackRate_,
            stateWhiteRate: stateWhiteRate_,
            nationalBlackRate: nationalBlackRate_,
            nationalWhiteRate: nationalWhiteRate_,
        }
    }

    raceBarGraphData = (fips) => {
        const dbData_ = this.dataChunks.es_all_demographics.copy(); 
        console.log("FILE es_all_demographics", dbData_); 
        const racesAndIntents_ = dbData_
            .where('statefips', '=', fips)
            .where('gender_num', '=', 0)
            .where('race_num', '!=', 0)
            .fetch();

        return racesAndIntents_;
    }

    raceDonutGraphData = (fips) => {
        const dbData_ = this.dataChunks.es_all_demographics.copy();
        console.log("A072423 FILE es_all_demographics", dbData_);  
        const racesAndIntents_ = dbData_
            .where('statefips', '=', fips)
            .where('gender_num', '=', 0)
            .where('race_num', '!=', 0)
            .fetch();

        dbData_.reset();        
        
        const allRacesAndIntents = dbData_
            .where('statefips', '=', fips)
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .fetch();
        console.log("A072423 allRacesAndIntents", allRacesAndIntents);  
        dbData_.reset();

        let intentGenderlessTotals_ = {};
        if (fips === "00"){ // If USA is selected
            intentGenderlessTotals_ = dbData_
                .where('statefips', '=', fips)
                .where('gender_num', '=', 0)
                .fetch();
        }else{
            intentGenderlessTotals_ = dbData_
                .where('statefips', '=', fips)
                .where('gender_num', '=', 0)
                .where('race_num', '=', 0)
                .fetch();
        }
        
        return {
            racesAndIntents: racesAndIntents_,
            allRacesAndIntents: allRacesAndIntents,
            intentGenderlessTotals: intentGenderlessTotals_
        };
    }

    dbQueryableVars = () => {
        // state	fips_state	first_year	last_year	intent	intent_num	race_ethnicity	race_ethnicity_num	gender	gender_num	total_deaths	age_adj_rate
        //const dbData = this.dataChunks.es_database_data.copy();
        //console.log("FILE es_database_data", dbData);        
        const dbData = this.dataChunks.es_all_demographics.copy();
        console.log("FILE es_all_demographics", dbData);
        const intentTypes = dbData
            .groupBy('intent_num')
            .fetch();

        const intentArray = [];

        Object.keys(intentTypes).forEach( key => {
            const firstObj = intentTypes[key][0];

            intentArray.push(
                {
                    intent_num: key,
                    intent: firstObj.intent,
                }
            )
        })

        dbData.reset();

        const genders = dbData
            .groupBy('gender_num')
            .fetch();

        const genderArray = []

        Object.keys(genders).forEach( key => {
            const firstObj = genders[key][0];

            genderArray.push(
                {
                    gender_num: key,
                    gender: firstObj.gender,
                }
            )
        })

        dbData.reset();

        const races = dbData
            .groupBy('race_num')
            .fetch();

        const racesArray = [];

        Object.keys(races).forEach( key => {
            const firstObj = races[key][0];

            racesArray.push(
                {
                    gender_num: key,
                    race_ethnicity: firstObj.race_ethnicity,
                }
            )
        })

        return {
            intents: intentArray,
            genders: genderArray,
            races: racesArray
        };
    }

    countyTopline = (intent) => {
        if(intent == 1) {         
            const overviewData = this.dataChunks.es_all_demographics.copy();
            console.log("020723 FILE es_all_demographics", overviewData);
            const overviewDeaths = overviewData
                .where('state', '=', 'United States')
                .where('intent_num', '=', '1')
                .first();
            console.log("020723 overviewDeaths", overviewDeaths);
            return overviewDeaths;
        }
        if(intent == 2) {
            const suicideData = this.dataChunks.es_all_demographics.copy();
            console.log("021423 FILE es_all_demographics", suicideData);          
            const nationalSuicides = suicideData
                .where('state', '=', 'United States')
                .where('intent_num', '=', '2')
                .first();
            return nationalSuicides;
        }
        if(intent == 3) {
            const homicideData = this.dataChunks.es_all_demographics.copy();
            console.log("020723 FILE es_all_demographics", homicideData);
            const nationalHomicides = homicideData
                .where('state', '=', 'United States')
                .where('intent_num', '=', '3')
                .first();
            console.log("020723 nationalHomicides", nationalHomicides);
            return nationalHomicides;
        }
        if(intent == 11) {
            const injuryData = this.dataChunks.es_gun_deaths_by_county.copy();
            console.log("020723 FILE es_gun_deaths_by_county", injuryData);
            const nationalInjuries = injuryData
                .where('state', '=', 'United States')
                .where('intent_num', '=', '11')
                .first();
            console.log("020723 nationalInjuries", nationalInjuries);
            return nationalInjuries;
        }
    }

    dbQuery(intent = 1, gender = 0, race = 0, fips = []) {
        //const dbData = this.dataChunks.es_database_data.copy();
        //console.log("FILE es_database_data", dbData)        
        const dbData = this.dataChunks.es_all_demographics.copy();
        console.log("FILE es_all_demographics", dbData)

            dbData
            /* .where('fips_state', '=', fips) */
            .where('gender_num', '=', gender)
            .where('race_num', '=', race);

            if(intent != 1) {
                dbData.where('intent_num', '=', intent);
            }

            if(fips.length > 0) {
                dbData.whereIn('statefips', fips)
            }

            const results = dbData
            .where('statefips', '!=', "00")
            .sortBy('age_adj_rate', 'desc')
            .where('intent_num', '=', 1).orWhere('intent_num', '=', 2).orWhere('intent_num', '=', 3)
            .groupBy('statefips')
            .fetch();

        dbData.reset();
        console.log("010421 results", results);
        console.log("010421 intent", intent);
        console.log("010421 gender", gender);
        console.log("010421 race", race);
        dbData
            .where('gender_num', '=', gender)
            .where('race_num', '=', race)
            .where('intent_num', '=', intent)
            .where('statefips', '!=', "00")

        if(fips.length > 0) {
            dbData.whereIn('statefips', fips);
        }
        console.log("010421 dbData", dbData);
        const maxDeaths = dbData
            //.where('intent_num', '=', 1).orWhere('intent_num', '=', 2).orWhere('intent_num', '=', 3)
            .max('age_adj_rate');
        console.log("010421 maxDeaths", maxDeaths);

        dbData.reset();

        const nationalStat = dbData
            .where('statefips', '=', "00")
            .where('gender_num', '=', 0)
            .where('race_num', '=', 0)
            .where('intent_num', '=', intent)
            .first();

        return {
            results: results,
            max: maxDeaths.toFixed(1),
            nationalStat: nationalStat
        }
    }

    transHomicideData() {
        const transData = this.dataChunks.es_trans_homicide_data.copy();
        console.log("021224 FILE es_trans_homicide_data", transData)
        //const countAll = transData.count();
        const countAll = transData._baseContent.length;
    
        /*transData
            .where('weapon', 'contains', 'gun');
    
        const countGun = 100; //transData.count();
    
        console.log("021224 FILE es_trans_homicide_data", transData)
    
        transData
            .where('victim_race', '=', 'black') // black,latinx latinx,black
            //.orWhere('victim_race', '=', 'black,latinx')
            //.orWhere('victim_race', '=', 'latinx,black') 
        transData
            .where('victim_gender', 'contains' ,'w')
            .orWhere('victim_gender', 'contains', 'ge')
        */
        let countGun = 0;
        let countGunRaceGender = 0;
        transData._baseContent.forEach(function(obj) {
            console.log("021224 obj", obj)
            console.log("021224 obj?.weapon?", obj.weapon)
            if (obj.weapon){
                if (obj.victim_gender !== 0 && obj.victim_race !== 0 && obj.weapon !== 0){
                    if (obj?.weapon?.includes("gun")){
                        countGun++;
                        if (obj?.victim_race?.includes("black") && (obj?.victim_gender?.includes("w") || obj?.victim_gender?.includes("ge"))){
                            countGunRaceGender++;
                        }
                    }
                }
            }
        })
    
        console.log("021224 transData", transData)
        //const countGunRaceGender = 50; //transData.count();
        console.log("021224 countGunRaceGender", countGunRaceGender)
        console.log("021224 countGun", countGun)
        console.log("021224 countAll", countAll)
        console.log("021224 (countGunRaceGender/countGun) * 100", (countGunRaceGender/countGun) * 100)
        return {
            countAll: countAll,
            countGun: countGun,
            countGunRaceGender: countGunRaceGender,
            percentBlackFemale: (countGunRaceGender/countGun) * 100
        }
    }

    ordinalSuffixOf(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    percentage(decimaled) {
        let percentage = decimaled * 100;
        percentage = Math.round(percentage);
        return percentage + '%';
    }    
    percentageSupressed(decimaled,suppressedSuicideIntent) {
        console.log("011822 suppressedSuicideIntent", suppressedSuicideIntent)
        if (suppressedSuicideIntent <= 0.05){
            return '<5%';
        }else{
            let percentage = (suppressedSuicideIntent + 0.0499999999).toFixed(1) * 100;
            percentage = Math.round(percentage);
            return '<' + percentage + '%';
        }
    }
    formatBigNumber(x) {
        if(isNaN(x)) return x;
    
        if(x < 1000) { // fewer than 1 thousand -- return number as-is
            return x;
        }
        if(x < 100000) { // fewer than 100 thousand -- e.g., "10.1 Thousand"
            return (x/1000).toFixed(1) + " thousand";
        }
        if(x < 1000000) { // fewer than 1 million -- e.g., "100 Thousand"
            return (x/1000).toFixed(0) + " thousand"; 
        }
        if( x < 100000000) { // fewer than 100 million -- e.g., 10.1 Million"
            return (x/1000000).toFixed(1) + " million";
        }
        if(x < 1000000000) { // fewer than 1 billion -- e.g., "100 Million"
            return (x/1000000).toFixed(1) + " million";
        }
        if(x < 100000000000) { // fewer than 100 billion -- e.g., "10.1 Billion"
            return (x/1000000000).toFixed(1) + " billion";
        }        
        if(x < 1000000000000) { // fewer than 1 trllion -- e.g., "100 Billion"
            return (x/1000000000).toFixed(1) + " billion";
        }
        if(x < 100000000000000) { // fewer than 100 trllion -- e.g., "10.1 Trillion"
            return (x/100000000000).toFixed(1) + " trillion";
        }
        return "100 Trilion+";
    }    
    formatBigNumberETRFormula(x) {
        if(isNaN(x)) return x;
    
        if(x < 1000) { // fewer than 1 thousand -- return number as-is
            return x;
        }
        if(x < 100000) { // fewer than 100 thousand -- e.g., "10.1 Thousand"
            return (x/1000).toFixed(1) + " thousand";
        }
        if(x < 1000000) { // fewer than 1 million -- e.g., "100 Thousand"
            return (x/1000).toFixed(0) + " thousand"; 
        }
        if( x < 100000000) { // fewer than 100 million -- e.g., 10.1 Million"
            return (x/1000000).toFixed(1) + " million";
        }
        if( x < 950000000) { // fewer than 950 million -- e.g., 950.1 Million"
            return (x/1000000).toFixed(1) + " million";
        }
        if(x < 1000000000) { // fewer than 1 billion -- e.g., "100 Million"
            var nX = (x/1000000).toFixed(1);
            var nUnit = " million";
            if (nX >= 1000){
                nX = 1;
                nUnit = " billion";
            }
            return nX + nUnit;
        }
        if(x < 100000000000) { // fewer than 100 billion -- e.g., "10.1 Billion"
            return (x/1000000000).toFixed(1) + " billion";
        }        
        if(x < 1000000000000) { // fewer than 1 trllion -- e.g., "100 Billion" // divide by billion, round to 0.1 (as directed)
            return (x/1000000000).toFixed(1) + " billion";
        }
        if(x < 100000000000000) { // fewer than 100 trllion -- e.g., "10.1 Trillion"
            return (x/100000000000).toFixed(1) + " trillion";
        }
        return "100 Trilion+";
    }
}

export default (new ESData);
