import { loadAsset } from "../utils/load";

// helpers

const DEFAULT_OPTIONS = {
  selector: ".preload",
  container: document,
  classname_loading: "pre-loading",
  classname_loaded: "pre-loaded"
};

export default class LazyLoad {
  totalToLoad = 0;
  numLoaded = 0;
  constructor(options = {}) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);

    const nodeList = this.options.container.querySelectorAll(
      this.options.selector
    );
    
    nodeList.forEach(element => {
      this.totalToLoad++;
      this.load(element);
    });
  }

  load = element => {
    if(element.classList.contains(DEFAULT_OPTIONS.classname_loaded)) return;
    
    const src = element.dataset.src || element.dataset.backgroundUrl || null;
    const alt = element.dataset.alt || "";
    // console.log('element', element)
    if (!src) {
      console.info(
        'LazyLoad: No "data-src" nor "data-background-url" attached to the element, no loading',
        src
      );
      return;
    }

    const isBackgroundImage = element.dataset.backgroundUrl !== undefined;

    element.classList.add(this.options.classname_loading);

    loadAsset(src)
      .then(asset => {
        element.classList.remove(this.options.classname_loading);

        if (isBackgroundImage) {
          element.style.backgroundImage = `url(${src})`;
        } else {
          asset.setAttribute("alt", alt);
          element.appendChild(asset);
        }

        setTimeout(() => {
          element.classList.add(this.options.classname_loaded) 
          this.numLoaded++;
          if(this.numLoaded == this.totalToLoad) {
            this.onAllLoaded();
          }
        }, 100);
      })
      .catch(error => console.error("Lazyload::", error));
  }

  onAllLoaded() {
    if(this.options.onAllLoaded) {
      this.options.onAllLoaded();
    }
  }

  dispose() {
    
  }
}
