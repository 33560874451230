import debounce from '../helpers/Debounce'
import { subscribeViewport } from '../helpers/Viewport'

class Accordion {
	constructor({selector = 'body', tabClass = 'tab'} = {}) {
        this.tabClass = tabClass
        this.selector = selector
		this.element = document.querySelector(this.selector)
		this.tabsElements = Array.from(this.element.querySelectorAll('.' + this.tabClass))

		this.currentTabIndex = null
		this.heightPropertyName = '--tab-height'
		this.heightHistogramUnselected = '--histogram-unselected'
		this.heightHistogramUnselectedMobile = '--histogram-unselected-mobile'

		this.tabHeights = []

		this.clickEvent_bound = this.clickEvent.bind(this)
		this.updateHeights_bound = this.updateHeights.bind(this)
		this.updateHeights_debounced = debounce(this.updateHeights_bound, 100)
		this.updateHeights()
		this.addEventListeners()
	}

	destruct() {
		this.removeEventListeners()
		this.tabsElements.forEach(element => {
			element.style.removeProperty(this.heightPropertyName)
		})
	}

	// Add listeners for click and resize events
	addEventListeners() {
		this.tabsElements.forEach(element => {
            element.querySelectorAll('.-accordion-toggle')
                .forEach($button => {
                    $button.addEventListener('click', this.clickEvent_bound)
                })
		})
		subscribeViewport(this.updateHeights_debounced)
	}

	removeEventListeners() {
		this.tabsElements.forEach(element => {
			element.querySelectorAll('.-accordion-toggle')
            .forEach($button => {
                $button.removeEventListener('click', this.clickEvent_bound)
            })
		})
	}

	// Update heights to animate on tab open
	updateHeights() {
		this.tabsElements.forEach((element, i) => {
			const height = element.querySelector('.-accordion-tabContent').getBoundingClientRect().height
			this.tabHeights[i] = height
			element.style.setProperty(this.heightPropertyName, height)
			// If it is an instance of the -nested- histogram accordion, set heights accordingly
			var adHocHeight = 600;
			if (i === 5){
				console.log("102421 i", i)
				console.log("102421 element", element)

				//document.querySelector(".es-grid-congress-show-more").click()
			}
			if (element.classList.contains("congress-special")){ 
				adHocHeight = 625;
			}
			if (element.classList.contains("es-bucket-histogram")){ 
				if (element.classList.contains("-selected")){
					// Stuff to do when closing the hisltogram accordion
					element.style.setProperty(this.heightHistogramUnselected, 95) // remove the extra 600px when the accordion is open, keep onlt about 95px for spacing
					element.style.setProperty(this.heightHistogramUnselectedMobile, 40)
					element.querySelector('.es-histogram-expand-selector').classList.add('es-histogram-expand-selector-open');
					element.querySelector('.es-typo-subheader3').innerHTML = "Show Less";
					if (i === 5){
						if(!element.classList.contains("im-open")){
							element.classList.add("im-open")
							element.classList.remove("im-closed")
						}
					}
				}else{
					// Stuff to do when closing the hitogram accordion
					element.style.setProperty(this.heightHistogramUnselected, adHocHeight) // 600px allows first few rows to be displayed on the histogram only.
					element.style.setProperty(this.heightHistogramUnselectedMobile, 500) 
					element.querySelector('.es-histogram-expand-selector').classList.remove('es-histogram-expand-selector-open');
					element.querySelector('.es-typo-subheader3').innerHTML = "Show More";
					// TODO: finally, scroll the user back up to the part of the histogram that is visible (otherwise they'lll likely be all the way down by the footer)
					//window.scrollTo(0, CostingHistogram.$headerTitle.offsetTop - 20)
					if (i === 5){
						if(element.classList.contains("im-open")){
							element.classList.remove("im-open")
							element.classList.add("im-closed")
						}
					}
				}
			}
		})
	}

	// Handle click on the tab
	clickEvent(event) {
		let target = event.target

		while (target !== null && !target.classList.contains(this.tabClass)) {
			target = target.parentElement !== null ? target.parentElement : null;
		}

		if (target !== null) {
			this.updateCurrentTab(parseInt(target.getAttribute('data-index')))
		}
		this.updateHeights()
		/* 
		If this is a child accordion, call the same function (binded and 'debounced') after parent accordions have fully animated.
		It is necesscary to modify the height of the parent accordion after the child accordion has expanded (otherwise elements will crash or be hidden)  
		*/
		//if (event.target.classList.contains("es-bucket-child")){
			setTimeout(this.updateHeights_debounced, 600);
		//}
		
	}

	updateCurrentTab(index) {
		this.currentTabIndex = index
		console.log("061721 this.currentTabIndex",this.currentTabIndex)
		this.tabsElements.forEach((element, i) => {
			/* 
			For the homicide/suicide histogram accordions we need to update the height of the homicide 
			or suicide drawer (parent to the histogram) so that the histogram element does not overwrite things
			... need to undo the full height too when closing the histogram accordion
			*/
			if(i === this.currentTabIndex) {
				console.log("061721 i",i)
				console.log("061721 element",element)
				//console.log("061721 element title > ",element.getElementsByClassName("es-bucket-title")[0].innerText)
				if(element.classList.contains('-selected')) {
					element.classList.remove('-selected');
					element.style.setProperty('--tab-is-selected', 0);
				} else {
					element.classList.add('-selected');
					element.style.setProperty('--tab-is-selected', 1);
				}
			}
		})
	}
}

export default Accordion
