import { loadAsset } from "../utils/load";

// helpers
import ScrollReveal from "./ScrollReveal";

// client doesn't like the lazy loading.
const loadOnScroll = true;

const DEFAULT_OPTIONS = {
  selector: ".lazy",
  container: document,
  classname_loading: "lazy-loading",
  classname_loaded: "lazy-loaded"
};

export default class LazyLoad {
  constructor(options = {}) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);

    const nodeList = this.options.container.querySelectorAll(
      this.options.selector
    );
    this.aScrollReveals = [];

    nodeList.forEach(element => {
      // console.log(element)
      if (loadOnScroll) {
        const scrollReveal = new ScrollReveal(element, { callback: this.load });
        this.aScrollReveals.push(scrollReveal);
      } else {
        this.load({
          dispose: () => {},
          element: element,
        });
      }
    });
  }

  load = scrollReveal => {
    console.log("------ scroll reveal. load it.");
    const element = scrollReveal.element;

    scrollReveal.dispose();

    if (element == null) {
      console.log("----- Stopping lazy load, element is null");
      return;
    }

    if(element.classList.contains(DEFAULT_OPTIONS.classname_loaded)){
       return;
    }

    const src = element.dataset.src || element.dataset.backgroundUrl || null;
    const alt = element.dataset.alt || "";
    const role = element.dataset.role || "";
    const arialabel = element.dataset.arialabel || "";

    if (!src) {
      console.info(
        '------ WARNING MisConfigured LazyLoad. No "data-src" nor "data-background-url" attached to the element, no loading',
        element
      );
      return;
    }

    const isBackgroundImage = element.dataset.backgroundUrl !== undefined;

    element.classList.add(this.options.classname_loading);

    loadAsset(src)
      .then(asset => {
        element.classList.remove(this.options.classname_loading);

        if (isBackgroundImage) {
          element.style.backgroundImage = `url(${src})`;

        } else {
          asset.setAttribute("alt", alt);
          asset.setAttribute("role", role);
          asset.setAttribute("aria-label", arialabel);

          element.appendChild(asset);
        }

        setTimeout(
          () => element.classList.add(this.options.classname_loaded),
          0
        );
      })
      .catch(error => console.error("Lazyload::", error));
  };

  dispose() {
    this.aScrollReveals.forEach(scrollReveal => {
      scrollReveal.dispose();
      scrollReveal = null;
    });

    this.aScrollReveals = [];
  }
}
