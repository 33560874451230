import breakpoints from "../constants/breakpoints";


const aCB = {};

const callbacks = () => {
  for (const key in aCB) {
    if (aCB[key]) aCB[key]();
  }
}

class Viewport {
  _width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  _height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  _pageHeight = 0;

  set width(width) {
    const update = width !== this.width;
    this._width = width;
    if (update) callbacks();
  }

  get width() {
    return this._width;
  }

  set height(height) {
    const update = height !== this.height;
    this._height = height;

    if (update) callbacks();
  }

  get height() {
    return this._height;
  }

  set pageHeight(pageHeight) {
    const update = pageHeight !== this.pageHeight;
    this._pageHeight = pageHeight;

    if (update) callbacks();
  }

  get pageHeight() {
    return this._pageHeight;
  }
}

const viewport = new Viewport();

export const getViewport = () => ({width: viewport.width, height: viewport.height, pageHeight: viewport.pageHeight});
export const setViewport = viewportObject => {

  viewport.width = viewportObject.width;
  viewport.height = viewportObject.height;
  viewport.pageHeight = viewportObject.pageHeight;

  return getViewport();
}

export const getBreakpoint = () => {
  const viewportW = getViewport().width;

  let breakpoint = 'xl-desktop';
  viewportW < breakpoints['xl-desktop'] ? breakpoint = 'desktop' : null;
  viewportW < breakpoints['desktop'] ? breakpoint = 'small-desktop' : null;
  viewportW < breakpoints['small-desktop'] ? breakpoint = 'tablet' : null;
  viewportW < breakpoints['tablet'] ? breakpoint = 'mobile' : null;

  return breakpoint;
};
export const getDetailedBreakpoint = () => {
  const viewportW = getViewport().width;
  const viewportH = getViewport().height;

  let breakpoint = 'desktop';
  viewportW < breakpoints['desktop'] ? breakpoint = 'desktop' : null;
  viewportW < breakpoints['smaller-desktop'] ? breakpoint = 'smaller-desktop' : null;
  viewportW < 1070 ? breakpoint = 'small-desktop' : null;
  viewportW < breakpoints['tablet'] ? breakpoint = 'tablet' : null;
  viewportW < breakpoints['mobile'] ? breakpoint = 'mobile' : null;

  return breakpoint;
};

const idGenerator = () => {
  const S4 = () => {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export const subscribeViewport = (callback, _id = null) => {
  const id = _id ? _id + '_' + idGenerator() : idGenerator();

  aCB[id] = callback;

  // console.log('subscribe', id);

  // return the dispose function
  return () => {
    delete aCB[id];
  };
}
