// import ease from 'utils/ease';

const aCB = {};

const callbacks = () => {
  for (const key in aCB) {
    if (aCB[key]) aCB[key]();
  }
}

class Scroll {
  _x = 0;
  _y = 0;

  set x(x) {
    const update = x !== this.x;
    this._x = x;
    if (update) callbacks();
  }

  get x() {
    return this._x;
  }

  set y(y) {
    const update = y !== this.y;
    this._y = y;

    if (update) callbacks();
  }

  get y() {
    return this._y;
  }
}

const scroll = new Scroll();

export const getScroll = () => ({x: scroll.x, y: scroll.y, direction: scroll.direction});
export const setScroll = (scrollObject) => {
  // for safari bouncing
  if (scrollObject.y < 0) scrollObject.y = 0;

  scroll.direction = scroll.y > scrollObject.y ? 'up' : 'down';
  scroll.x = scrollObject.x;
  scroll.y = scrollObject.y;

  return getScroll();
}

const idGenerator = () => {
  const S4 = () => {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export const subscribeScroll = (callback, _id = null) => {
  const id = _id ? _id + '_' + idGenerator() : idGenerator();

  aCB[id] = callback;

  // console.log('subscribe', id);

  // return the dispose function
  return () => {
    delete aCB[id];
  };
}

const paramsScrollTo = {
  y: 0,
  to: 0,
  from: 0,
  duration: 0,
  iteration: 0,
  fps: 60
}

export const scrollTo = (to, duration = 1, cb = null) => {
  paramsScrollTo.from = window.scrollY || window.pageYOffset;
  paramsScrollTo.duration = duration;
  paramsScrollTo.to = to;
  paramsScrollTo.cb = cb;

  if (paramsScrollTo.to !== paramsScrollTo.from) window.requestAnimationFrame(scrollToRAF);
  else if (cb) cb();
}

/* const scrollToRAF = () => {
  // const deltaValue = paramsScrollTo.to - paramsScrollTo.from;
  const duration = paramsScrollTo.fps * paramsScrollTo.duration;

  paramsScrollTo.y = ease.easeOutCubic(paramsScrollTo.iteration, paramsScrollTo.from, paramsScrollTo.to, duration);
  paramsScrollTo.iteration++;

  paramsScrollTo.y = Math.round(paramsScrollTo.y);

  if (paramsScrollTo.iteration <= duration) {
    window.scrollTo(0, paramsScrollTo.y);
    requestAnimationFrame(scrollToRAF);
  }
  else {
    paramsScrollTo.iteration = 0;
    paramsScrollTo.y = 0;
    if (paramsScrollTo.cb) paramsScrollTo.cb();
  }
} */
