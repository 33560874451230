export const VIDEO_EXTENSIONS = ['mp4', 'webm', 'ogg'];
export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'svg'];
export const VIDEO = 'video';
export const IMAGE = 'image';
export const FILE = 'file';
export const TYPES = [VIDEO, IMAGE, FILE];

const isChrome = (typeof(window) !== 'undefined') ? /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) : false;
export let SUPPORT_WEBP = isChrome;
export let WEBP_TESTED = isChrome;


export const testWebP = (cb) => {
  if (typeof(window) === 'undefined') return;
  if (SUPPORT_WEBP || WEBP_TESTED) return;

  var webP = new Image();
  webP.onload = webP.onerror = function () {
    WEBP_TESTED = true;
    SUPPORT_WEBP = webP.height === 2;
    if (cb) cb();
  };
  webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
};

export const getMobileWidthQuery = (MAX_WIDTH = 768) => {
  if (typeof(window) === 'undefined') return MAX_WIDTH;

  let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

  // Pixel ratio
  let qualityRatio = 1;
  if (window.devicePixelRatio) qualityRatio = window.devicePixelRatio;

  // If bad connection, we downgrade
  let type = null;
  if (navigator && navigator.connection && navigator.connection.type) type = navigator.connection.type;
  if (navigator && navigator.connection && navigator.connection.effectiveType) type = navigator.connection.effectiveType;

  if (type !== null && (type !== 'wifi' && type !== '4g')) qualityRatio = 1;

  width *= qualityRatio;

  if (width > MAX_WIDTH) width = MAX_WIDTH;

  return width;
}

// if (typeof(window) !== 'undefined') testWebP();

export const getQuery = (file, query = '') => {
  if (!file) return query;
  if (!WEBP_TESTED) testWebP();

  let asset = null;
  if (typeof file === 'object') asset = {url: file.get('url')};
  if (typeof file === 'string') asset = {url: file};

  // get extension
  const extension = _getExtension(asset);

  // get type
  const type = _getType(asset);

  if (type === IMAGE) {


    if (extension !== 'svg' && extension !== 'gif') {

      // Webp
      if (SUPPORT_WEBP) {
        if (query.length && query.indexOf('fm=jpg') > -1) query = query.replace('fm=jpg', 'fm=webp')
        else query += query.length ? '&fm=webp' : 'fm=webp';
      } else if (extension !== 'png'){
        if (query.indexOf('fm=jpg') === -1) query += query.length ?  '&fm=jpg' : 'fm=jpg';
        // add quality
        query += '&q=85'
      }

    } else {
      // remove any fm query
      if (query.length && query.indexOf('fm=jpg') > -1) query = query.replace('fm=jpg', '')
    }

  }

  return query;
}

export const _getExtension = (asset, options = {}) => {
  if (asset.extension) return asset.extension;
  if (options.extension) return options.extension;

  // based on contentType
  if (asset.contentType) {
    return asset.contentType.split('/').pop().toLowerCase();
  }

  // based on url
  const extension = asset.url.split('.').pop();
  return extension.split('?')[0].toLowerCase();
}

export const _getType = (asset, options = {}) => {
  if (asset.type) return asset.type;
  if (options.type) return options.type;

  // based on contentType
  if (asset.contentType) {
    const contentType = asset.contentType.split('/').pop();
    if (TYPES.indexOf(contentType) > -1) return TYPES.indexOf(contentType);
  }

  // based on extension
  options.extension = options.extension ? options.extension : _getExtension(asset, options);

  if (IMAGE_EXTENSIONS.indexOf(options.extension) > -1) return IMAGE;
  if (VIDEO_EXTENSIONS.indexOf(options.extension) > -1) return VIDEO;
  return FILE;
}

export const loadJSON = (url, options = {}) => {
    return fetch(url, options)
        .then((response) => {
            // console.log('response:url', url, response);
            return response.json();
        })
        .catch((err) => {
            console.error('load()::Json error', err, url);
            return false;
        })
        .then((data) => {
            if (!data) {
                console.info('data are empty', url);
                return false;
            }

            if (options.delay) {
                setTimeout(() => {
                    return data;
                }, options.delay);
            }

            return data;
        })
        .catch((err) => {
            console.error('load()::Data error', err, url);
            return false;
        });
};


export const loadImg = (url, options = {}) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        let xhr = null;

        let query = options.query ? options.query : '';
        if (query.length) url += '?' + query;

        const useXHR = false;

        // Trigger the loading with a XHR request
        if (useXHR && window.URL && window.Blob && window.XMLHttpRequest) {
            xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';

            xhr.onerror = (e) => {
                xhr.abort();
                xhr = null;

                // load old fashion way
                if (img.complete && img.height) {
                    resolve(img);
                } else {
                    img.addEventListener('load', () => {
                        resolve(img);
                    });
                    img.addEventListener('onerror', (err) => {
                        reject(err);
                    });

                    img.src = url;
                }
            };

            xhr.onload = (e) => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let extension = url.split('.').pop();
                        extension = extension.split('?')[0].toLowerCase();

                        if (options.extension) extension = options.extension;

                        // Obtain a blob: URL for the image data.
                        const arrayBufferView = new window.Uint8Array(xhr.response);
                        const blob = new Blob([arrayBufferView], {type: 'image/' + extension});
                        const imageUrl = URL.createObjectURL(blob);

                        // load from the cache as the blob is here already
                        img.addEventListener('load', () => {
                            resolve(img);
                        });
                        img.addEventListener('onerror', (err) => {
                            reject(err);
                        });

                        img.src = imageUrl;
                    } else {
                        xhr.abort();
                        xhr = null;

                        // something went wrong..
                        img.addEventListener('load', () => {
                            resolve(img);
                        });
                        img.addEventListener('onerror', (err) => {
                            reject(err);
                        });

                        img.src = url;
                    }
                }
            };

            xhr.send();
        } else if (img.complete && img.height) {
            resolve(img);
        } else {
            img.addEventListener('load', () => {
                resolve(img);
            });
            img.addEventListener('error', (err) => {
              reject(err);
            });

            img.src = url;
        }
    });
};

export const loadVideo = (url, options = {autoplay: true, muted: true, loop: true, controls: false}) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');

        video.autoplay = options.autoplay;
        video.muted = options.muted;
        video.loop = options.loop;
        video.controls = options.controls;

        video.src = url;

        let hasLoaded = false;
        const timer = setTimeout(() => {
            if (!hasLoaded) resolve(video);
            hasLoaded = true;
        }, 3000);

        if (video.readyState > 3) {
            resolve(video);
            return;
        }

        video.oncanplaythrough = () => {
            video.pause();
            video.currentTime = 0;
            video.oncanplaythrough = null;
            if (hasLoaded) return;
            clearTimeout(timer);
            hasLoaded = true;
            resolve(video);
        };

        video.load();
        video.play();
    });
};

export const loadAsset = (asset_, options = {}) => {
    const asset = typeof (asset_) === 'string' ? {url: asset_} : asset_;

    // get extension
    options.extension = _getExtension(asset, options);

    // get type
    options.type = _getType(asset, options);

    switch (options.type) {
        case VIDEO: return loadVideo(asset.url, options);
        case IMAGE: return loadImg(asset.url, options);
        case FILE: return loadJSON(asset.url, options);
        default: return loadJSON(asset.url, options);
    }
};


