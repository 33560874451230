import { TimelineMax, Circ } from 'gsap/all'
import { subscribeViewport, getBreakpoint } from './Viewport'
import { subscribeScroll, getScroll } from './Scroll'
import scrollLock from './ScrollLock'

class Header {
    constructor ($elt = null) {
        this.$element = $elt ? $elt : document.createElement("div");
        this.isMobileMenuOpen = false

        this.currentLayout = getBreakpoint()

        this.$burgerButton = this.$element.querySelector('.es-header-burgermenu')
        this.$navList = this.$element.querySelector('.es-header-navlist')

        this.height = this.$element.getBoundingClientRect().height

        this.onScroll()

        this.addEventListeners()

        this.getCurrentPage();
    }

    addEventListeners () {
        this.$burgerButton.addEventListener('click', this.toggleMobileMenu)
        subscribeViewport(() => {
            if (getBreakpoint() !== 'mobile' && this.currentLayout !== getBreakpoint()) {
                this.animateMobileMenuIn(0)
                this.currentLayout = getBreakpoint()
            } else if (this.currentLayout !== getBreakpoint()) {
                this.isMobileMenuOpen = false
                this.animateMobileMenuOut(0)
                this.currentLayout = getBreakpoint()
            }
        })
        subscribeScroll(this.onScroll)

        //document.addEventListener("turbolinks:render", this.getCurrentPage.bind(this));
    }

    onScroll = () => {
        if (getScroll().y > this.height) {
            this.setState('small')
        } else {
            this.setState('large')
        }
    }

    setState (state) {
        if (this.state !== state) {
            this.state = state
            document.body.classList.remove('-es-header-small')
            document.body.classList.remove('-es-header-large')
            document.body.classList.add('-es-header-' + state)
        }
    }

    toggleMobileMenu = async open => {
        if (typeof open === 'boolean') {
            this.isMobileMenuOpen = open
        } else {
            this.isMobileMenuOpen = !this.isMobileMenuOpen
        }
        if (this.isMobileMenuOpen) {
            this.$element.classList.add('es-mobilemenu-open')
            scrollLock.lock()
            this.animateMobileMenuIn()
        } else {
            await this.animateMobileMenuOut()
            this.$element.classList.remove('es-mobilemenu-open')
        }
    }

    animateMobileMenuIn (durationFactor = 1) {
        return new Promise(resolve => {
            const tl = new TimelineMax({
                onComplete: resolve
            })
            tl
                .to(this.$element, 0.3 * durationFactor, {
                    '--es-menu-open-animation': 1,
                    ease: Circ.easeOut
                })
                .set(this.$navList, {
                    display: 'flex'
                }, 0)
                .staggerFromTo(this.$navList.querySelectorAll('li'), 0.2 * durationFactor, {
                    opacity: 0,
                    scale: 0.9
                }, {
                    opacity: 1,
                    scale: 1,
                    ease: Circ.easeOut
                }, 0.08 * durationFactor)
        })
    }

    animateMobileMenuOut (durationFactor = 1) {
        scrollLock.unlock()
        return new Promise(resolve => {
            const tl = new TimelineMax({
                onComplete: resolve
            })
            tl
                .staggerTo(this.$navList.querySelectorAll('li'), 0.08 * durationFactor, {
                    opacity: 0,
                    scale: 0.9,
                    ease: Circ.easeOut
                }, 0.05 * durationFactor)
                .to(this.$element, 0.2 * durationFactor, {
                    '--es-menu-open-animation': 0,
                    ease: Circ.easeOut
                })
                .set(this.$navList, {
                    display: 'none'
                })
        })
    }

    getCurrentPage() {
        const currentPageId = document.querySelector('.es-main-content-wrapper').getAttribute('id');
        const navItems = this.$navList.querySelectorAll('.es-header-navlistlink');
        navItems.forEach(element => {
            element.classList.remove('es-current-page');
        });
        switch(currentPageId) {
            case 'everystat-landing-page':
                navItems[0].classList.add('es-current-page');
                break;
            case 'everystat-database-page':
                navItems[1].classList.add('es-current-page');
                break;            
            case 'everystat-methodology-page':
                navItems[2].classList.add('es-current-page');
                break;
            case 'everystat-faqs-page':
                navItems[3].classList.add('es-current-page');
                break;
            case 'everystat-pdfs-page':
                navItems[4].classList.add('es-current-page');
                break;
            default:
                break;
        }
        
        if(currentPageId === 'everystat-database-page') {
            this.$element.classList.add('database-header');
        } else {
            this.$element.classList.remove('database-header');
        }
    }
}

export default Header
