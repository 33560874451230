import '../scss/main.scss';

//import Turbolinks from "turbolinks";

import { initObserver } from "./helpers/IntersectionObserver";
import { setScroll } from "./helpers/Scroll";
import { setViewport, getBreakpoint } from "./helpers/Viewport";
import Header from './helpers/Header';
import 'focus-visible/dist/focus-visible.min.js';

class Entry {

  init = () => {
    //Turbolinks.start();

    this.currentView = null;

    initObserver({
      threshold: .5
    });

    this.bindEvents();

    /*document.addEventListener("turbolinks:before-visit", e => {
      if (getBreakpoint() === 'mobile') {
        this.header.toggleMobileMenu(false)
      }
    });

    document.addEventListener("turbolinks:visit", e => {
      if (this.currentView) this.currentView.hide();
    });

    document.addEventListener("turbolinks:load", e => {
      this.currentSlug =  e.data.url;
      // this.header.update(this.currentSlug);
      this.pageLoaded();
     });

     document.addEventListener('turbolinks:before-cache', e => {
       console.log('before:cache')
       if (this.currentView) {
         this.currentView.destroy()
         this.currentView = null
       }
     })*/
    this.pageLoaded();
    this.$elt = document.body;
    this.$html = document.documentElement;

    this.mobileBp = 768

    this.layout = 'desktop'

    this.header = new Header(this.$elt.querySelector('.es-headerwrapper'))

  };

  bindEvents() {
    window.addEventListener(
      "scroll",
      () => {
        this.scrollTicket = true;
      },
      false
    );
    window.requestAnimationFrame(this.onUpdate);
    window.addEventListener("orientationchange", () => this.onResize(), false);
    window.addEventListener("resize", () => this.onResize(), false);
  }

  onHeaderResize = (iscondensed) => {
    if(this.currentView && this.currentView.onHeaderResize) {
      this.currentView.onHeaderResize(iscondensed);
    }
  }

  onUpdate = () => {
    if (typeof window === "undefined") return;
    if (this.scrollTicket) {
      this.scrollTicket = false;

      const scrollObj = {
        y: window.scrollY || window.pageYOffset
      };

      setScroll(scrollObj);
    }

    window.requestAnimationFrame(this.onUpdate);
  };

  onResize = () => {
    const viewportObj = {
      width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
      pageHeight: Math.max(this.$elt.scrollHeight, this.$elt.offsetHeight, this.$html.clientHeight, this.$html.scrollHeight, this.$html.offsetHeight)
    };
    setViewport(viewportObj);
  }

  pageLoaded = () => {
    // Routing
    const currentPage = document.querySelector("#everystat-content").firstElementChild;
    // console.log("pageLoaded -- " + currentPage.id);

    switch (currentPage.id) {
      case "everystat-landing-page":
        import(/* webpackChunkName: "homepage" */ "./classes/Landing")
          .then(this.currentViewImported)
          .catch(error =>
            console.log(
              "An error occurred while loading the Homepage component",
              error
            )
          );
        break;
      case "everystat-faqs-page":
        import(/* webpackChunkName: "homepage" */ "./classes/FAQs")
          .then(this.currentViewImported)
          .catch(error =>
            console.log(
              "An error occurred while loading the Homepage component",
              error
            )
          );
        break;
      case "everystat-database-page":
        import(/* webpackChunkName: "homepage" */ "./classes/DB")
          .then(this.currentViewImported)
          .catch(error =>
            console.log(
              "An error occurred while loading the Homepage component",
              error
            )
          );
        break;
      default:
        // this.currentViewImported({ default: PageView }, currentPage);
        break;
    }
  };

  currentViewImported = async (
    { default: CurrentModule },
    el = null,
    options = {}
  ) => {
    if (this.currentView) {
      this.currentView.dispose();
    }
    this.currentView = new CurrentModule(el, options);

    this.currentView.init();
    this.currentView.show();
  };

}
const entry = new Entry();

document.addEventListener("DOMContentLoaded", () => {
  entry.init();
  console.log("080723 entry", entry);
  //console.log("080723 stateList", stateList);
}, false);
