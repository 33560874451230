export default class Base {
    /**
     * Object as associative array of all the states
     * @type {Object}
     */
    _states = {};

    set states(states) {
      for (const state in states) {
        // eslint-disable-line guard-for-in
        this._states[state] = states[state];
      }
    }

    get states() {
      return this._states;
    }

    constructor(options = {}) {
      this.$element = options && options.el ? options.el : document.querySelector("#everystat-content").firstElementChild;
      this.options = options;
    }

    setState(partialState = {}, callback) {
      if (
        typeof partialState !== "object" &&
        typeof partialState !== "function" &&
        partialState !== null
      ) {
        console.error(
          "setState(...): takes an object of state variables to update or a " +
            "function which returns an object of state variables."
        );
        return;
      }

      for (const key in partialState) {
        // eslint-disable-line guard-for-in
        this.states[key] = partialState[key];
      }

      if (callback) callback();
      this.render();
    }

    init() {
      this.bindEvents();
    }
    /**
     * Bind your events here
     */
    bindEvents() {}

    /**
     * Unbind your events here
     */
    unbindEvents() {}

    /**
     * Call render function if you wanna change the component
     * based on states/data
     */
    render() {}

    /**
     * Dispose the component
     */
    dispose() {}

    /**
     * Destroy the component
     */
    destroy() {}
}
