import "intersection-observer";

// So on mobile, if we have the polyfill, it starts loading immediately without waiting for a user interaction
IntersectionObserver.prototype.POLL_INTERVAL = 100; // Time in milliseconds.

const onIntersection = entries => {
  entries.forEach(entry => {
    // callback and clean
    if (entry.isIntersecting) {
      callback(entry.target);
    }
  });
};

const callback = target => {
  let item = null;

  aCbs.forEach(item_ => {
    if (item_.target === target) item = item_;
  });

  if (item) {
    // call cb
    item.cb(target);

    // remove from array
    unobserve(target);
  }
};

let aCbs = [];

export const SUPPORT_INTERSECTION_OBSERVER =
  typeof window !== "undefined" && "IntersectionObserver" in window;

export let observer = null;

export const initObserver = (config = {}) => {
  disposeObserver();
  observer = SUPPORT_INTERSECTION_OBSERVER
    ? new IntersectionObserver(onIntersection, config)
    : null;
};

export const disposeObserver = () => {
  if (!observer) return;

  // Stops the IntersectionObserver object from observing any target.
  observer.disconnect();

  // Reset CBs
  aCbs.length = 0;
  aCbs = [];

  observer = null;
};

export const observe = (target = null, cb = null) => {
  if (!observer) return;
  if (!target) return;
  if (!cb) return;

  // Add to the Cb array if not in there
  let check = true;
  aCbs.forEach(item => {
    if (item.target === target) check = false;
  });

  if (!check) return;

  aCbs.push({ target, cb });

  // Observe
  observer.observe(target);
};

export const unobserve = (target = null) => {
  if (!observer) return;

  // unobserve them all
  if (!target && observer) {
    observer.disconnect();

    aCbs.length = 0;
    aCbs = [];

    return;
  }

  // unobserve a specific one
  if (target && observer) {
    observer.unobserve(target);

    let index = -1;

    aCbs.forEach((item_, idx) => {
      if (item_.target === target) index = idx;
    });

    if (index >= 0) aCbs.splice(index, 1);
  }
};
